// import styles from './index.less'
import React from 'react';
import './index.less'
import Header from '../../components/header'
import Footer from '../../components/footer'

function Policy () {
  return (
      <React.Fragment>
      <Header blank={true}/>
      <div className='policyContainer'>
          <h1>Privacy Policy</h1>

          <p>We at CAEX Canada Express Consulting  respect your privacy and take the issue of Internet privacy very seriously. By accepting the CAEX Canada Express Consulting Terms of Service (as a user of Weetok’s services), you explicitly consent to the collection, processing, transfer, use, and disclosure of any information, including personal information which you provide to us as outlined in this Privacy Statement and agree that the terms not defined below have the meanings set forth in the Terms of Service.</p>

          <p>As CAEX Canada Express Consulting  improves its services, we may modify our Privacy Statement at any time and for any reason without giving you prior notice. By accessing and using our Site and services following any such modifications, you agree to follow and be bound by the Terms of Service and Privacy Statement, each as modified from time to time. For this reason, we urge you to check here for updates from time to time.</p>

          <h2>Personal Information</h2>

          <p>In order to use this website, we request contact information, such as your name, email address, and additional demographic information. We use this information to contact users about the services on our site for which they have expressed interest and may also use this information as a user's sign-in information. We will not provide a user’s personal information to third parties without the consent of the user.</p>

          <h2>Services through the Site</h2>

          <p>Users provide information during the process of using the CAEX Canada Express Consulting  service. We break down users of our service into two categories, Seekers and Sharers (you may register as both a Seeker and a Sharer). Seekers must provide financial information such as a credit card number and expiration date in order to pay for and use the CAEX Canada Express Consulting  service. Sharers must provide bank account information in order to receive their payment from CAEX Canada Express Consulting . The information we collect from Seekers and Sharers is to provide users with the best possible service. If we have trouble providing that service, we may use contact information to get in touch with the user.</p>

          <h2>Seeker-Sharer Communication Using the CAEX Canada Express Consulting  Service</h2>

          <p>The main purpose of CAEX Canada Express Consulting  is to allow registered Sharers to provide Seekers with services that Seekers need. CAEX Canada Express Consulting  hosts live conversations and scheduling between Seekers and Sharers through its website. In the process, CAEX Canada Express Consulting  does not disclose the phone numbers or email addresses of its users, nor do we share this information among interacting Sharers and Seekers. CAEX Canada Express Consulting  does not screen or edit the content of communications between Seekers and Sharers. Such communications are personal and private unless both parties agree to additional distribution. CAEX Canada Express Consulting  keeps information relayed between the Seeker and the Sharer confidential. No other users of CAEX Canada Express Consulting  have access to this information.</p>

          <h2>Security</h2>

          <p>Your information is stored on our servers. Despite preventative security measures taken by CAEX Canada Express Consulting , persons may unlawfully intercept or access transmissions or private communications, and other persons may abuse or misuse personal information that they collect from our site. Accordingly, CAEX Canada Express Consulting  is not liable for the improper release or misuse of personal information or private communications.</p>

          <h2>Correcting and Updating Personal Information</h2>

          <p>If your personal information changes or you no longer wish to use our services, please email our User Support at support@caex-caonsulting.com</p>

          <h2>Contacting Us</h2>

          <p>If you have any questions about our Privacy Policy, the practices of this site, or your dealings with CAEX Canada Express Consulting, please contact us at support@caex-caonsulting.com</p>
      </div>
      <Footer/>
      </React.Fragment>
  )
}

export default Policy