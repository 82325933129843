import React, {useState, useEffect} from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import './index.less'
import img_event from '../../asset/img/doc_top.png'
import img_cover from '../../asset/img/doc_cover.png'
import img_corner from '../../asset/img/docs_corner.png'
import consult_pp1 from '../../asset/img/consult-pp1.png'
import consult_pp2 from '../../asset/img/consult-pp2.png'
import consult_pp3 from '../../asset/img/consult-pp3.png'
// import img_corner_mobile  from '../../asset/img/weetokplus_corner_mobile.png'
import img_grey_line from '../../asset/img/grey_line.png'
import img_line from '../../asset/img/docs_line.png'
import rosalyn_wechat from '../../asset/img/rosalyn_wechat.jpg'
import { Modal, Tag, Form, Input, Button, message, Select } from 'antd';
import { CheckCircleOutlined, FilePdfOutlined, UserOutlined, WechatOutlined, MailOutlined} from '@ant-design/icons';
import { loadStripe } from '@stripe/stripe-js';
import { useLocaleContext } from '../../utils/context'
import { Helmet } from 'react-helmet';
import { sendConsultRequest } from  '../../api/user' 

const stripePromise = loadStripe('pk_live_51N4wIoHrsABESZ3IQ8MEyhTqkPNefCtqGIvnecGZgoll0Sb6p88tOAUSUPKjOJWg3Lxmd5fKGGYYM8qqxwAjleDK00yj5IVGby');
export default function Index() {

    const [ count, SetCount] = useState(3)
    const [ page, SetPage] = useState(1)
    const { locale } = useLocaleContext()

    const cancelConfirm = () => {
        console.log('cancel')
    }
    const hiddeTidoLogo = (iframeContent) => {
        console.log('点击了')
        setTimeout(()=> {
            var element = document.querySelector('iframe[title="Tidio Chat"]');
            var iframeContent = element.contentDocument || element.contentWindow.document;
            var elementChild = iframeContent.querySelector('a[aria-label="Powered by Tidio."]');
            console.log(elementChild)
            // const body = iframeContent.querySelector('body');
            if (elementChild) {
                elementChild.parentNode.style.display = 'none'
            }
        },500)
    }

    useEffect(() => {
        setTimeout(() => {
            if (count > 0) {
                var element = document.querySelector('iframe[title="Tidio Chat"]');
                // console.log(element)
                if (element === null || element === undefined) {
                    SetCount(count-1)
                } else {
                    console.log(element)
                    var iframeContent = element.contentDocument || element.contentWindow.document;
                    console.log(iframeContent)
                    // element.addEventListener('click',hiddeTidoLogo)
                    const body = iframeContent.querySelector('body');
                    body.addEventListener('click',hiddeTidoLogo)
                }
                
            }
        }, 2000);
    },[count])

    const okConfirm = async (type) => {
        console.log('type',type)
        let successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamqyy"
        let price = 'price_1PV0W1HrsABESZ3ImbhCPEUU'

        if (type === "check") {
            successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnacheck"
            price = 'price_1NCSnVHrsABESZ3I7EH8bPfk'
        }
        if (type === "consult30") {
            successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamzxwd"
            price = 'price_1PV0XfHrsABESZ3IuuHBuknx'
        } 
        if (type === "consult") {
            successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamzxwd"
            price = 'price_1PV0W1HrsABESZ3ImbhCPEUU'
        }


        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          lineItems: [{
            price: price, // Replace with the ID of your price
            quantity: 1,
          }],
          mode: 'payment',
          successUrl: successUrl,
          cancelUrl:  window.location.origin+'/payresult/cancel?type=3',
        });
    }

    const confirmToPay = (type) => {
        console.log(type)
        let text = "你确定前往支付"
        
        if (type === "check") {
            text += "【 签证分数自测文档 】"
        } else if (type === "consult30") {
            text += "【专业私定制咨询 - 30分钟】"
        } else {
            text += "【专业私定制咨询】"
        }
        Modal.confirm({
            centered: true,
            title: 'Confirm',
            icon: <CheckCircleOutlined style={{color: "#ce3636"}} />,
            content: text,
            okText: '确认',
            cancelText: '取消',
            onCancel() {
                cancelConfirm()
            },
            onOk(){
                okConfirm(type)
            }
            
        });
    }
    
    const handleSubmitConsult = () => {
        form.validateFields().then(values => {
            console.log(values.email)
            console.log(values.wechat)
            console.log(values.name)
            console.log(values.type)
            // form.resetFields()
            sendConsultRequest({email: values.email, wechat: values.wechat, name: values.name, type: values.type}).then(res => {
            if (res.status === 200) {
                message.success(res.message)
                form.resetFields()
            } else {
                message.warning(res.message)
            }
            })
        }).catch(err => {
            return
        })
    }
    const { Option } = Select;
    const [form] = Form.useForm()
    return (
        <React.Fragment>
            <Helmet>
                <title>专业定制咨询</title>
                <meta
                name="description"
                content="一键选择心仪的签证顾问，专业私人1v1咨询顾问团队"
                />
                <meta
                name="keywords"
                content="美签咨询，美签预约，美签免面签申请，美签申请，美签代办，美签加急"
                />
            </Helmet>
            <Header/>
            <div className="docs_container">
                <div className="docs_header">
                        <img src={img_event} alt="" className="docs_img_event" />
                        <div className="docs_title_con">
                            <h2 className="docs_title">美签专业定制咨询</h2>
                            <p className="docs_des">
                                一键选择心仪的签证顾问，专业私人1v1咨询顾问团队
                            </p>
                        </div>
                </div>
                <div className='team-consult'>
                    <div className='person-consult-container'>
                        <img className='consult_ppl' src={consult_pp1} alt="consult_pp1"></img>
                        <div className='consult_contain_top'>
                            <p>
                                高级资深顾问
                            </p>
                            <p>
                                Ray Wong
                            </p>
                        </div>
                        <ul>
                            <li>
                                <p>🏅 金牌美加签证顾问</p>
                            </li>
                            <li>
                                <p>🧑🏻‍🎓 芝加哥大学理学学士</p>
                            </li>
                            <li>
                                <p>🧑🏻‍🎓 UBC大学理学硕士</p>
                            </li>
                            <li>
                                <p>⭐️ 资深移民留学文案专家</p>
                            </li>
                        </ul>
                        <div className='tag-container'>
                            <Tag className='tag'>F1学生签证</Tag>
                            <Tag className='tag'>B1B2商务旅游签证</Tag>
                        </div>
                        <div className='tag-container'>
                            <Tag className='tag'>C1过境签证</Tag>
                            <Tag className='tag'>J1访问学者签证</Tag>
                        </div>
                        <div className='tag-container'>
                            <Tag className='tag'>H1B工作签证</Tag>
                            <Tag className='tag'>各类附属配偶签证</Tag>
                        </div>
                    </div>
                    <div className='person-consult-container'>
                        <img className='consult_ppl' src={consult_pp2} alt="consult_pp2"></img>
                        <div className='consult_contain_top'>
                            <p>
                                高级资深顾问
                            </p>
                            <p>
                                Cathy Zhang
                            </p>
                        </div>
                        <ul>
                            <li>
                                <p>🏅 金牌美加签证顾问</p>
                            </li>
                            <li>
                                <p>👩🏻‍🎓 UBC大学文学学士</p>
                            </li>
                            <li>
                                <p>⭐️ 资深移民留学文案专家</p>
                            </li>
                            <li>
                                <p>✍🏻 BBC英文杂志撰稿人</p>
                            </li>
                        </ul>
                        <div className='tag-container'>
                            <Tag className='tag'>F1学生签证</Tag>
                            <Tag className='tag'>B1B2商务旅游签证</Tag>
                        </div>
                        <div className='tag-container'>
                            <Tag className='tag'>C1过境签证</Tag>
                            <Tag className='tag'>J1访问学者签证</Tag>
                        </div>
                        <div className='tag-container'>
                            <Tag className='tag'>H1B工作签证</Tag>
                            <Tag className='tag'>各类附属配偶签证</Tag>
                        </div>
                    </div>
                    <div className='person-consult-container'>
                        <img className='consult_ppl' src={consult_pp3} alt="consult_pp3"></img>
                        <div className='consult_contain_top'>
                            <p>
                                高级资深顾问
                            </p>
                            <p>
                                Laura Huang
                            </p>
                        </div>
                        <ul>
                            <li>
                                <p>🏅 金牌美加签证顾问</p>
                            </li>
                            <li>
                                <p>👩🏻‍🎓 波士顿大学文学学士</p>
                            </li>
                            <li>
                                <p>📚 资深移民留学文案专家</p>
                            </li>
                            <li>
                                <p>💻 前互联网公司产品经理</p>
                            </li>
                        </ul>
                        <div className='tag-container'>
                            <Tag className='tag'>F1学生签证</Tag>
                            <Tag className='tag'>B1B2商务旅游签证</Tag>
                        </div>
                        <div className='tag-container'>
                            <Tag className='tag'>C1过境签证</Tag>
                            <Tag className='tag'>J1访问学者签证</Tag>
                        </div>
                        <div className='tag-container'>
                            <Tag className='tag'>H1B工作签证</Tag>
                            <Tag className='tag'>各类附属配偶签证</Tag>
                        </div>
                    </div>
                </div>
                <div className='consult'>
                    <h2 className="con_title">一、预约时间 （ 联系客服 ）</h2>
                    <img className="img_line" src={img_line} alt=""></img>
                    <div className="container">
                        { page === 1 &&
                            <>
                            {/* <Icon type="caret-right" />  */}
                            <div className="methodDes">请任选一种方式联系我们，发送咨询</div>
                            <div className="wrap_consult">
                                <div className="left">
                                {/* <IconFont type="icon-form"className={styles.form} /> */}
                                    <div className="methodTitle"> 方式一</div>
                                    <p className="formAlert">请告诉我们你的基本信息，我们将匹配客服专员联系你</p>
                                    <Form 
                                        layout="vertical"
                                        form={form}
                                    >
                                        <Form.Item
                                            name="email"
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your email!',
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'Please input correct email format!',
                                                }
                                            ]}
                                        >
                                            <Input prefix={<MailOutlined />} size="large" placeholder="邮箱" />
                                        </Form.Item>
                                        <Form.Item 
                                            name='wechat'
                                            hasFeedback
                                        >
                                            <Input prefix={<WechatOutlined />} size="large" placeholder="微信号"></Input>
                                        </Form.Item>
                                        <Form.Item
                                            name="name"
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请留下您的称呼!',
                                                },
                                            ]}
                                        >
                                            <Input prefix={<UserOutlined className="site-form-item-icon" />} size="large" placeholder="称呼" />
                                        </Form.Item>                        
                                        <Form.Item
                                            name="type" 
                                            hasFeedback                                      
                                        >
                                            <Select placeholder="选择类型" size="large">
                                                <Option value="1">私人订制咨询30分钟</Option>
                                                <Option value="2">私人订制咨询无限时</Option>
                                                <Option value="3">美签申请</Option>
                                                <Option value="4">其他</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="info" size="large" onClick={handleSubmitConsult}>
                                                 确认提交
                                             </Button>
                                        </Form.Item> 
                                    </Form>
                                </div> 
                                <div className="right">
                                {/* <IconFont type="icon-we-chat" className={styles.wechat} /> */}
                                    <div className="methodTitle">方式二</div>
                                    <div className="con">
                                        <img src={rosalyn_wechat} alt="rosalyn_wechat" />
                                        {/* <QrcodeHandler position='web_pkg_form' className={null}/> */}
                                        <p>扫码免费预约时间
                                            <br />
                                            订制属于你的专属咨询方案
                                        </p>
                                    </div>
                                </div>
                            </div>
                            </> }
                        {/* { page === 2 &&
                        <div>
                                <Result status="success" title="提交成功，请添加Lexy老师微信咨询~" extra={
                                    <div className={styles.advisor_qrcode}>
                                        <img className={styles.img_wechat} src={img_wechat} alt="qrcode"></img>
                                        <p>有任何问题可以联系Lexy老师</p>
                                    </div>
                                }/>
                        </div> } */}
                    </div>

                </div>
                <div className="plan">
                    <h2 className="con_title">二、服务类型 （ 点击选择 ）</h2>
                    <img className="img_line" src={img_line} alt=""></img>
                    {/* <div className={styles.btn_group_plan}>
                        <Button className={styles.btn_getplan} data-seed="weetokplus-consult-plan-mainland" onClick={e => openConsult("Mainland")}><span className={styles.span_location}>海归/国内</span>通道</Button> 
                        <Button className={styles.btn_getplan} data-seed="weetokplus-consult-plan-overseas" onClick={e => openConsult("Overseas")}><span className={styles.span_location}>海外</span>求职通道</Button>
                    </div> */}
                    {/* <Button className={styles.btn_getplan} data-seed="weetokplus-consult-plan" onClick={e => openConsult(e)}>免费咨询</Button>  */}
                    <div className="plan_con" onClick={ e => confirmToPay('consult30')}>
                        <img className="img_cover" src={img_cover} alt=""></img>
                        <FilePdfOutlined className="file_type" />
                        <img className="img_corner" src={img_corner} alt=""></img>
                        {/* <img className="img_corner_mobile" src={img_corner_mobile} alt=""></img> */}
                        <h3>专业私定制咨询</h3>                     
                        <h2>一切签证疑难问题咨询解答 - 30分钟</h2>
                        <img className="img_greyline_plan" src={img_grey_line} alt=""></img>
                        <h5>咨询方式：</h5>
                        <p>电话 / Wechat 文字、语音 / 邮件 等通用联系方式</p>
                        <h5>咨询包括：</h5>
                        <p>解答你在签证过程中遇到的各种疑难问题。无论你面临的问题是关于签证类型、申请流程、所需文件、面试准备，还是其他相关问题，我们的专业顾问都会为你提供专业的指导和解答。</p>
                        <h5>咨询特点：</h5>
                        <p> 通过这个服务，你可以获得有针对性的建议，帮助你更加顺利地完成签证申请过程，减少不必要的困扰和错误。 </p>
                        <h5>适合人群：</h5>
                        <p>无论你是首次申请签证还是需要延期或更改签证，我们都将竭诚为你提供支持，确保您的签证申请顺利进行。</p>
                        <div><span className='docs_price_now'>$22.99</span><span className='docs_price_original'>99.99 美金</span></div>
                        <div className='has_bought'>18592人已使用</div>
                    </div>
                    <div className="plan_con" onClick={ e => confirmToPay('consult')}>
                        <img className="img_cover" src={img_cover} alt=""></img>
                        <FilePdfOutlined className="file_type" />
                        <img className="img_corner" src={img_corner} alt=""></img>
                        {/* <img className="img_corner_mobile" src={img_corner_mobile} alt=""></img> */}
                        <h3>专业私定制咨询</h3>                     
                        <h2>一切签证疑难问题咨询解答 - 多次无限时</h2>
                        <img className="img_greyline_plan" src={img_grey_line} alt=""></img>
                        <h5>咨询方式：</h5>
                        <p>电话 / Wechat 文字、语音 / 邮件 等通用联系方式</p>
                        <h5>咨询包括：</h5>
                        <p>解答你在签证过程中遇到的各种疑难问题。无论你面临的问题是关于签证类型、申请流程、所需文件、面试准备，还是其他相关问题，我们的专业顾问都会为你提供专业的指导和解答。</p>
                        <h5>咨询特点：</h5>
                        <p> 通过这个服务，你可以获得有针对性的建议，帮助你更加顺利地完成签证申请过程，减少不必要的困扰和错误。 </p>
                        <h5>适合人群：</h5>
                        <p>无论你是首次申请签证还是需要延期或更改签证，我们都将竭诚为你提供支持，确保您的签证申请顺利进行。</p>
                        <div><span className='docs_price_now'>$89.99</span><span className='docs_price_original'>$229.99 美金</span></div>
                        <div className='has_bought'>23145人已使用</div>
                    </div>
                    {/* <div className="plan_con" onClick={() => confirmToPay('ds160')}>
                        <img className="img_cover" src={img_cover} alt=""></img>
                        <img className="img_corner" src={img_corner} alt=""></img>
                        <FilePdfOutlined className="file_type" /> */}
                        {/* <img className="img_corner_mobile" src={img_corner_mobile} alt=""></img> */}
                        {/* <h3>DS-160终极攻略</h3>                     
                        <h2>DS-160系统性整理填写攻略</h2>
                        <img className="img_greyline_plan" src={img_grey_line} alt=""></img>
                        <h5>清晰了解申请表填写重点，省时省力，快速解锁各种盲点和危险点</h5>
                        <p>Follow文档提示要求，懒人式填写信息，高效解锁申请表</p>
                        <h5>攻略文档包括</h5>
                        <p> DS-160 · 快速填写 · 中英翻译 · 填写攻略 · 危险盲点 · 错误点排查 · 省事妙招 · 修改润色 · 更新方式</p>
                        <h5>阶段结束后，你将获得：</h5>
                        <p>快速精确填写 DS-160,  检查填写问题，排除错误点及盲点</p>
                        <div><span className='docs_price_now'>$9.99</span><span className='docs_price_original'>$99.99 美金</span></div>
                        <div className='has_bought'>1276人已使用</div>
                    </div> */}
                    <div className="plan_con" onClick={() => confirmToPay('check')}>
                        <img className="img_cover" src={img_cover} alt=""></img>
                        <img className="img_corner" src={img_corner} alt=""></img>
                        <FilePdfOutlined className="file_type" />
                        {/* <img className="img_corner_mobile" src={img_corner_mobile} alt=""></img> */}
                        <h3>申请分数评估测试</h3>                     
                        <h2>快速获取美签申请分数</h2>
                        <img className="img_greyline_plan" src={img_grey_line} alt=""></img>
                        <h5>深度自我背景检查，提前了解过签率</h5>
                        <p>通过根据文档指示, 自我逐条评分，得出总分比对申请分数最低要求</p>
                        <h5>攻略文档包括</h5>
                        <p> 签证评分内幕 · 分数自测 · 申签需求 · 申签规避点 · 背景了解 · B1/B2 · H1b · H4 · F1 · F2 · O1 · O2 · O3 · J1 · J2 </p>
                        <h5>阶段结束后，你将获得：</h5>
                        <p>赢在起跑线上，不让你的签证费和时间打水漂，美签是否能过？自测打分一次搞定</p>
                        <div><span className='docs_price_now'>$4.99</span><span className='docs_price_original'>$49.99 美金</span></div>
                        <div className='has_bought'>3276人已使用</div>
                    </div>
                    {/* <div className="plan_con" onClick={() => confirmToPay('b1/b2')}>
                        <img className="img_cover" src={img_cover} alt=""></img>
                        <img className="img_corner" src={img_corner} alt=""></img>
                        <FilePdfOutlined className="file_type" /> */}
                        {/* <img className="img_corner_mobile" src={img_corner_mobile} alt=""></img> */}
                        {/* <h3>B1/B2 材料清单</h3>                     
                        <h2>商务/旅游签 - 系统性整理面试材料及攻略</h2>
                        <img className="img_greyline_plan" src={img_grey_line} alt=""></img>
                        <h5>深度检查面签材料，杜绝被抽查</h5>
                        <p>通过阅读文档, 自我对照材料的准确性和真实性，匹配申请要求及面签官的要求等</p>
                        <h5>攻略文档包括</h5>
                        <p> B1与B2签证区别认清 · 面试注意事项 · 签证常问问题 · 领事馆城市选择 · 酒店攻略 · 证明材料模板 · 专属顾问1次个性化咨询 · B1签证 · B1/B2签证 · B2签证 </p>
                        <h5>阶段结束后，你将获得：</h5>
                        <p>掌握商务/旅游签必备面签材料清单，了解面试官询问逻辑，提高面试信心及提升过签概率</p>
                        <div><span className='docs_price_now'>$8.99</span><span className='docs_price_original'>$89.99 美金</span></div>
                        <div className='has_bought'>1535人已使用</div>
                    </div> */}
                    {/* <div className="plan_con" onClick={() => confirmToPay('f1')}>
                        <img className="img_cover" src={img_cover} alt=""></img>
                        <FilePdfOutlined className="file_type" />
                        <img className="img_corner" src={img_corner} alt=""></img> */}
                        {/* <img className="img_corner_mobile" src={img_corner_mobile} alt=""></img> */}
                        {/* <h3>F1 材料整理清单</h3>                     
                        <h2>学生签 - 系统性整理面试材料及攻略</h2>
                        <img className="img_greyline_plan" src={img_grey_line} alt=""></img>
                        <h5>深度检查面签材料，杜绝被抽查</h5>
                        <p>通过阅读文档, 自我对照材料的准确性和真实性，匹配申请要求及面签官的要求等</p>
                        <h5>攻略文档包括</h5>
                        <p> 面试注意事项 · 签证常问问题  · 城市选择 · 酒店攻略 · 证明材料模式 · 专属顾问1次个性化咨询 · F1 </p>
                        <h5>阶段结束后，你将获得：</h5>
                        <p>掌握目标行业必备知识技能及提升相关背景经历，强化行业深度理解</p>
                        <div><span className='docs_price_now'>$8.99</span><span className='docs_price_original'>$89.99 美金</span></div>
                        <div className='has_bought'>1087人已使用</div>
                    </div> */}
                    {/* <div className="plan_con" onClick={() => confirmToPay('h1b')}>
                        <img className="img_cover" src={img_cover} alt=""></img>
                        <FilePdfOutlined className="file_type" />
                        <img className="img_corner" src={img_corner} alt=""></img> */}
                        {/* <img className="img_corner_mobile" src={img_corner_mobile} alt=""></img> */}
                        {/* <h3>H1B 材料整理清单</h3>                     
                        <h2>工作签 - 系统性整理面试材料及攻略写</h2>
                        <img className="img_greyline_plan" src={img_grey_line} alt=""></img>
                        <h5>深度检查面签材料，杜绝被抽查</h5>
                        <p>通过阅读文档, 自我对照材料的准确性和真实性，匹配申请要求及面签官的要求等</p>
                        <h5>攻略文档包括</h5>
                        <p> 面试注意事项 · 签证常问问题 · DS-160填写攻略 · 城市选择 · 酒店攻略 · 证明材料模式 · 专属顾问1次个性化咨询 · H1B · H4 </p>
                        <h5>阶段结束后，你将获得：</h5>
                        <p>掌握目标行业必备知识技能及提升相关背景经历，强化行业深度理解</p>
                        <div><span className='docs_price_now'>$8.99</span><span className='docs_price_original'>$89.99 美金</span></div>
                        <div className='has_bought'>695人已使用</div>
                    </div> */}
                </div>
                {/* { webinarList.length > 0 && 
                <div className={styles.bullet_elements}>
                    { webinarList.map((node, index) => {
                            if ( node.lecture.status !== 3 ) {
                                return  <div className={styles.upcoming_con} key={node.id}>
                                            <EventNode postObj={node} index={index} currentUser={currentUser}/>
                                        </div> 
                            }
                        }) 
                    }
                    { webinarList.map((node, index) => {
                        if (node.lecture.status === 3 ) {
                            return  <div className={styles.finished_con} key={node.id}>
                                        <EventNode postObj={node} index={index} user={currentUser} />
                                    </div> 
                        }    
                    })
                    }
                    <div className={styles.emptyele}></div>
                    <div className={styles.emptyele}></div>
                    <div className={styles.emptyele}></div>
                    <div className={styles.emptyele}></div>
                </div> } */}
                {/* { webinarList.length === 0 &&
                <div style={{height:'calc(100vh - 550px)'}}>
                    <div className="content_loading"><Spin indicator={antIcon} /></div>
                </div>
                } */}
            </div>

            <Footer/>
        </React.Fragment>
    )
}