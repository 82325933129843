import React, {useState} from 'react'
import {Button,Radio} from 'antd'
import './index.less'
import Header from '../../../components/header'
import Footer from '../../../components/footer'
import { loadStripe } from '@stripe/stripe-js';
import { CreditCardOutlined, ShoppingOutlined }from '@ant-design/icons';
import imgPackage from '../../../asset/img/plan/packages.svg'

const stripePromise = loadStripe('pk_live_51N4wIoHrsABESZ3IQ8MEyhTqkPNefCtqGIvnecGZgoll0Sb6p88tOAUSUPKjOJWg3Lxmd5fKGGYYM8qqxwAjleDK00yj5IVGby');
export default function Index() {
    const [serviceName, setServiceName] = useState("美签私人订制")
    const [userName, setUserName] = useState("小红书用户")
    const [price, setPrice] = useState("120")
    const [number, setNumber] = useState(1)

    useState(() => {
      const query = window.location.search
      const tk = query.split("?tk=")[1]
      console.log(tk)
      if (tk !== undefined) {
        const decodedString = atob(tk);
        const combine = new TextDecoder("utf-8").decode(new Uint8Array(Array.from(decodedString).map(char => char.charCodeAt(0))));
        console.log(combine)
        const combine_list = combine.split(";")
        setServiceName(combine_list[0])
        setUserName(combine_list[1])
        setPrice(combine_list[2])
        console.log(combine_list[3])
        if (combine_list[3] !== undefined) {
          setNumber(combine_list[3])
        }
      }
    },[])

    const handleClick = async () => {
        let successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnazdy"
        let price = 'price_1NCS9WHrsABESZ3I4fdPukDj'
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          lineItems: [{
            price: price, // Replace with the ID of your price
            quantity: number,
          }],
          mode: 'payment',
          successUrl: successUrl,
          cancelUrl:  window.location.origin+'/payresult/cancel',
        });
    };


    return (
    <React.Fragment>
      <Header blank={true}/>
      <div className="achWrap">
				<table>
					<tbody>
						<tr>
							<th>
								{/* <h2><IconFont type="icon-gouwuchexiadan" className="cartIcon" /> 我报名的项目</h2> */}
								<h2> <ShoppingOutlined style={{fontSize: 16, marginRight: 5}} />服务类型</h2>
							</th>
							<th>
								<h2>联系昵称</h2>
							</th>
							<th>
								<h2>总价</h2>
							</th>
						</tr>
						<tr>
							<td>
								<img src={imgPackage} alt="" className="imgPackage"></img>
								<div className="pkgName">
									 <p>{serviceName}</p>
								</div>
							</td>
							<td>
								<p>{userName}</p>
							</td>
							<td>
								<p>美金 ${price}</p>
							</td>
						</tr>
					</tbody>
				</table>
        <div className="paymentMethodCon">
          <h2>付款方式：</h2>
          <>
            {/* <Radio checked><IconFont type="icon-xinyongqia1" className={styles.imgCreditLogo} /></Radio> */}
            <Radio checked></Radio>
            <p className="paymentMethod">信用卡 / 借记卡</p>
          </>
        </div>
			{/* <Button size={'large'}  type={'primary'} className="btn_pay" ><Icon type="credit-card" /> 点击付款</Button>  */}
			  <Button size={'large'} onClick={e => handleClick()}   type={'primary'} className="btn_pay" ><CreditCardOutlined />点击付款</Button>
		</div>
    <Footer/>
    </React.Fragment>
  )
}