import React, { useEffect, useState } from 'react';
import { Result } from 'antd';
import wechat from '../../asset/img/wechat.png'
import './style.less';
import Header from '../../components/header'


export default function Success() {
  const [planName,setPlanName] = useState("面签预约")
  const mqyy = "a4dnu3kxnamqyy"
  const mqqfw = "a4dnu3kxnamqqfw"
  const mqqfwyy = "a4dnu3kxnamqqfwyy"
  const h1bDocs = 'a4dnu3kxnamgqwd'
  const f1Dpcs = 'a4dnu3kxnamxqwd'
  const b1b2Docs = 'a4dnu3kxnamlyqwd'
  const ds160Docs = 'a4dnu3kxnamds160wd'
  const consultDocs = 'a4dnu3kxnamzxwd'
  const customize = 'a4dnu3kxnazdy'
  const check ='a4dnu3kxnacheck'

  useEffect(() => {
    const query = window.location.search
    if (query === "") {
      window.location.replace(window.location.origin+"/payresult/cancel")    
    }
    const tk = query.split("?tk=")[1]
    if (tk === mqyy){
      setPlanName('面签预约')
    }
    else if (tk === mqqfw) {
      setPlanName('面签申请')
    } 
    else if (tk === mqqfwyy) {
      setPlanName('面签全方位')
    } 
    else if (tk === check) {
      setPlanName('【 签证分数自测文档 】')
    } 
    else if (tk === h1bDocs) {
      setPlanName('【 H1b 材料整理清单 】')
    } 
    else if (tk === f1Dpcs) {
      setPlanName('【 F1 材料整理清单 】')
    }  
    else if (tk === ds160Docs) {
      setPlanName('【 DS-160终极攻略费用 】')
    }  
    else if (tk === b1b2Docs) {
      setPlanName('【 商务/旅游签 - 系统性整理面试材料及攻略 】')
    }
    else if (tk === consultDocs) {
      setPlanName('【专业私定制咨询】')
    }  else if (tk === customize) {
      setPlanName('【金牌咨询 & 定制申请】')
    }
    else{
      window.location.replace(window.location.origin+"/payresult/cancel")
    }
  }, [])

  return (
    <>
      <Header blank={true}/>
      <div className="payresult_wrap">
        <Result
        className="result_wrap"
        status="success"
        title="支付成功!"
      >
        <div className="new_res">
          <p>亲爱的小伙伴，</p>
          <p>恭喜你已经成功支付 - <span style={{color: 'red', fontSize: 15,fontWeight:500}}>{planName}</span> - 的服务费，马上就给你安排起来!</p>
          {/* <p>订单号： <strong>{info.order_id}</strong></p> */}
          {/* <p>订单号 <strong>{info.order_id}2020090412</strong></p> */}
          <p style={{textAlign:'center', marginTop:'30px'}}>请将你的姓名和付款成功的截屏发送给客服</p>
          <img src={wechat} className="img_qrcode" style={{width:'150px',margin:'20px auto',display:'block'}} alt="" />
          {/* <Button onClick={e=>history.push('/panel/mycourse')} className={styles.btn_start_course} data-seed="payresult-99-mycourse">开始上课</Button> */}
          
          <p>Canada Express 祝你一切顺利！</p>
        </div>
      </Result> 
      </div>
    </>
  )
}