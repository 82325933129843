import { post, get, patch } from '../../utils/request'

export function userRegBind(data) {
    const  url = 'user/signup/bind'
    return post(url,data)
}

export function getSession(data) {
    const  url = 'user/session'
    return post(url,data)
}

export function userVerifyAccount(data) {
    const  url = 'user/password/pre_reset'
    return post(url,data)
}

export function userPasswordReset(data) {
    const url = 'user/password/reset'
    return post(url,data)
}

export function userLogin(data) {
    const url = 'user/signin'
    return post(url,data)
}

export function userRegCheck(data) {
    const url = 'user/signup/check'
    return post(url,data)
}

export function userReg(data) {
    const url = 'user/signup'
    return post(url,data)
}

export function userProfile() {
    const url = 'user/profile'
    return get(url)
}

export function userInfo(data) {
    const url = 'user/retrieve'
    return get(url,data)
}

export function userVisaInfo(data) {
    const url = 'user/profie'
    return post(url,data)
}

export function usCanadaSlot(data) {
    const url = 'service/us_canada_slot'
    return get(url,data)
}

export function userAuth(data) {
    const url = 'user/auth'
    return post(url,data)
}

export function userVisaInfoUpdate(data) {
    const url = 'user/retrieve'
    return patch(url,data)
}

export function sendConsultRequest(data) {
    const url = 'user/consult'
    return post(url,data)
}

export function submitDS160Form(data) {
    const url = 'user/ds160_form'
    return post(url,data)
}