// import styles from './index.less'
// https://uxdesign.cc/how-to-design-a-sleek-dashboard-ui-a90ba41f0af1
import React, { useEffect, useState } from 'react';
import { Calendar, Typography, Button, Divider, Badge, Descriptions, Card, Col, 
    Row, Statistic, Progress, Skeleton, notification, Modal, Form, Input, message} from 'antd';
import { ClockCircleOutlined, ArrowDownOutlined, LockOutlined, UserOutlined, ExclamationCircleOutlined, HeartOutlined }from '@ant-design/icons';
import './index.less'
import Header from '../../components/header'
// import Footer from '../../components/footer'
import { userInfo, userVisaInfo, usCanadaSlot, userAuth, userVisaInfoUpdate } from '../../api/user'
import { Link } from "react-router-dom";
import { useAtom } from 'jotai';
import { userEmailAtom } from '../../state';
import { Helmet } from 'react-helmet';

function Dashboard () {
    const [ count, SetCount] = useState(3)
    const [mobile, SetMobile] = useState(false); 
    const [ user, SetUser] = useState(null)
    const [ profile, SetProfile] = useState({})
    const [ skeletonLoading, SetSkeletonLoading] = useState(true)
    const [ skeletonLoadingCity, SetSkeletonLoadingCity] = useState(true)
    const [ cityData, SetCityData] = useState({})
    const [ updateCountDown, SetUpdateCountDown] = useState(0)
    const [ autoUpdateCityData, SetAutoUdateCityData] = useState(false)
    const [countdown, setCountdown] = useState(600);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [openforgot, setOpenforgot] = useState(false);
    const [userEmail, setUserEmail] = useAtom(userEmailAtom);
    /**
     * This behavior is not unique to atomWithStorage but is inherent to the asynchronous nature of storage operations.
     * It's important to note that the initial render of your component will use the default value while the storage operation is in progress. 
     * The actual value will be available on subsequent renders or when the storage operation completes.
     */
    const [initialized, setInitialized] = useState(false); // 页面初始化等待atomWithStorage的返回值



    const hiddeTidoLogo = (iframeContent) => {
        console.log('点击了')
        setTimeout(()=> {
            var element = document.querySelector('iframe[title="Tidio Chat"]');
            var iframeContent = element.contentDocument || element.contentWindow.document;
            var elementChild = iframeContent.querySelector('a[aria-label="Powered by Tidio."]');
            // console.log(elementChild)
            // const body = iframeContent.querySelector('body');
            if (elementChild) {
                elementChild.parentNode.style.display = 'none'
            }
        },500)
    }

    useEffect(() => {
      setTimeout(() => {
          if (count > 0) {
              var element = document.querySelector('iframe[title="Tidio Chat"]');
              // console.log(element)
              if (element === null || element === undefined) {
                  SetCount(count-1)
              } else {
                //   console.log(element)
                  var iframeContent = element.contentDocument || element.contentWindow.document;
                //   console.log(iframeContent)
                  // element.addEventListener('click',hiddeTidoLogo)
                  const body = iframeContent.querySelector('body');
                  body.addEventListener('click',hiddeTidoLogo)
              }
          }
      }, 2000);
    },[count])
    
    useEffect(() => {
        isMobile()
    },)

    const isMobile = () => {
        SetMobile(/(iPhone|iPod|Android|SymbianOS|Windows Phone)/i.test(navigator.userAgent))
    }

    useEffect(() => {
        if (!initialized) {
            console.log('Deafult value within useEffect:', userEmail);
            setInitialized(true);
        } else {
            if (userEmail !== null) {
                setIsModalOpen(false)
                userInfo({email: userEmail}).then(res => {
                    if (res.status === 200) {
                        SetSkeletonLoading(false)
                        SetUser(res.data)
                    }
                })
                userVisaInfo({email: userEmail}).then(res => {
                console.log(res)
                if (res.status === 200) {
                    SetProfile(res.data)
                    const user_visa_type = res.data.visa_type
                    let visa_type = ''
                    if (user_visa_type.includes("B2")) {
                    visa_type = 'B2'
                    } else if (user_visa_type.includes("H1B")) {
                    visa_type = 'B2'
                    }
                    usCanadaSlot({visa_type}).then(res => {
                    if (res.status === 200) {
                        SetCityData(res.data)
                        SetSkeletonLoadingCity(false)
                    }
                    })
    
                    }
                })
            } else {
                setIsModalOpen(true)
            }
        }
    },[userEmail,initialized])

    useEffect(() => {
        setTimeout(() => {
            if (count > 0) {
                var element = document.querySelector('iframe[title="Tidio Chat"]');
                // console.log(element)
                if (element === null || element === undefined) {
                    SetCount(count-1)
                } else {
                    // console.log(element)
                    var iframeContent = element.contentDocument || element.contentWindow.document;
                    // console.log(iframeContent)
                    // element.addEventListener('click',hiddeTidoLogo)
                    const body = iframeContent.querySelector('body');
                    body.addEventListener('click',hiddeTidoLogo)
                }
            }
        }, 2000);
    },[count])

    
    useEffect(() => {
        let countdownInterval;
        console.log(autoUpdateCityData)
        if (autoUpdateCityData) {
          countdownInterval = setInterval(() => {
            usCanadaSlot({"visa_type":profile.visa_type}).then(res => {
                if (res.status === 200) {
                    SetCityData(res.data)
                    SetSkeletonLoadingCity(false)
                }
            })
            
          }, 1000*countdown);
        }
        return () => {
          setCountdown(5)
          clearInterval(countdownInterval);
        };
    }, [autoUpdateCityData]);

    const stopUpdate = () => {
        SetAutoUdateCityData(false)
    }

    const startUpdate = () => {
       SetAutoUdateCityData(true)
    }
    
    const updateCityData = () => {
      if (updateCountDown === 0) {
        SetUpdateCountDown(60)
        SetSkeletonLoadingCity(true)
        usCanadaSlot({"visa_type":profile.visa_type}).then(res => {
            if (res.status === 200) {
                SetCityData(res.data)
                SetSkeletonLoadingCity(false)
            }
        })
        setTimeout(()=> {
            SetUpdateCountDown(0)
        }, 60000)
      } else {
        notification.info({
            message: `系统提醒`,
            description:
            "更新频率太快，请稍后再尝试！",
            placement: 'topLeft',
            duration: 6,
        });
      }
    }

    const handleOk = () => {
        // const email = form.getFieldValue('email')
        // const password = form.getFieldValue('password')
        form.validateFields().then(values => {
            console.log(values.email)
            console.log(values.password)
            userAuth({email: values.email, password: values.password}).then(res => {
                if (res.status === 200) {
                    setIsModalOpen(false);
                    message.success(res.message)
                    setUserEmail(values.email)
                    console.log(userEmail)
                } else {
                    message.warning(res.message)
                }
            })
        }).catch(err => {
            return
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        window.location.replace(window.location.origin+"/plan")
    };
    const handleCancelForgot =() => {
        setOpenforgot(false)
    }
    const handleForgotClick = (event) => {
      event.preventDefault();
      setOpenforgot(true)
    }

    const handleEditOk = (event) => {
        infoform.validateFields().then(values => {
            console.log(values.fullname)
            console.log(values.nickname)
            console.log(values.password)
            console.log(values.repassword)
            if (values.password !== values.repassword) {
                message.error("二次密码不一致")
                console.log("二次密码不一致")
                return
            } else {
                userVisaInfoUpdate({fullname: values.fullname, nickname: values.nickname, password: values.repassword, email: user.email}).then(res => {
                if (res.status === 200) {
                    message.success(res.message)
                    SetUser(res.data)
                    setIsEditModalOpen(false)
                } else {
                    message.warning(res.message)
                }
            })
            }   
        }).catch(err => {
            return
        })
    }

    const handleEditCancel = () => {
        setIsEditModalOpen(false);
    }

    const handleEditOpen = () => {
        infoform.setFieldValue("fullname", user.fullname)
        infoform.setFieldValue("nickname", user.nickname)
        setIsEditModalOpen(true)
    }
    const [form] = Form.useForm()
    const [infoform] = Form.useForm()
    return (
        <React.Fragment>
        <Helmet>
            <title>个人中心</title>
            <meta
                name="description"
                content="我的个人中心，实时查看我的申请预约状态"
            />
            <meta
                name="keywords"
                content="美签加急预约,美签办理,美签预约,美签申请,美签刷位置,美签刷签"
            />
        </Helmet>
        <Header/>
        <div className="dashboardContainer">
        <Modal
            zIndex={1001}
            open={openforgot}
            title={<><ExclamationCircleOutlined style={{marginRight: 5}} />登录故障检查</>}
            footer={null}
            onCancel={handleCancelForgot}
            width={450}
            centered
        >
            <p>1. 检查是否已购买服务，联系顾问开通账号?&nbsp;<Link to="/plan"> =>前往选择购买</Link></p> 
            <p>2. 检查输入密码是否有大小写等问题。( 如AaBb )</p> 
            <p>3. 若上述检查后仍有问题，请联系您的专属顾问寻求帮助。</p>
        </Modal>
        <Modal title="登录我的个人中心" width={400} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}  cancelText={"返回"} okText={"确认登录"} closable={false} maskClosable={false} centered>
            <Form
                name="normal_login"
                className="login-form"
                form={form}
                >
                <Form.Item
                    name="email"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                        {
                            type: 'email',
                            message: 'Please input correct email format!',
                        }
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} size="large" placeholder="邮箱" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your Password!',
                    },
                    {
                        whitespace: true,
                        message: 'Password cannot contain space!',
                    },
                    {
                        min: 6,
                        message: 'Password should be at least 6 characters',
                    },
                    ]}
                    hasFeedback
                >
                    <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="密码"
                    size="large"
                    />
                </Form.Item>
                <a href="/" className="login-form-forgot" onClick={handleForgotClick}>
                    没有账号 / 忘记密码 ?
                </a>
            </Form>
        </Modal>
        <Modal title="修改信息" width={400} open={isEditModalOpen} onOk={handleEditOk} onCancel={handleEditCancel}  cancelText={"取消"} okText={"确认修改"} closable={false} maskClosable={false}>
            <Form
                name="normal_login"
                className="login-form"
                form={infoform}
                >
                <Form.Item
                    name="fullname"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请输入你的姓名!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} size="large" placeholder="姓名" />
                </Form.Item>
                <Form.Item
                    name="nickname"
                    hasFeedback
                >
                    <Input prefix={<HeartOutlined className="site-form-item-icon" />} size="large" placeholder="昵称" />
                </Form.Item>
                <Divider>or</Divider>
                <Form.Item
                    name="password"
                    rules={[
                    {
                        whitespace: true,
                        message: 'Password cannot contain space!',
                    },
                    {
                        min: 6,
                        message: 'Password should be at least 6 characters',
                    },
                    ]}
                >
                    <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="新密码"
                    size="large"
                    />
                </Form.Item>
                <Form.Item
                    name="repassword"
                    rules={[
                    {
                        whitespace: true,
                        message: 'Password cannot contain space!',
                    },
                    {
                        min: 6,
                        message: 'Password should be at least 6 characters',
                    },
                    ]}
                >
                    <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="确认新密码"
                    size="large"
                    />
                </Form.Item>
            </Form>
        </Modal>
          <div className='mainContainer'>
            <div className='leftSide'>
              <Typography.Title level={1} className='leftSideTitle'>我的<br/>个人中心</Typography.Title>
              <div className='leftSideTitleDesc'> 最新各领馆预约时间 </div>
              {/* <Skeleton loading={skeletonLoading} paragraph={{rows: 18}} active > */}
                <div className="time_slot time_slot_active"><span className='city'><ClockCircleOutlined />Calgary</span><span className='time'> { skeletonLoadingCity  === true && cityData["Calgary"] === undefined ? <LockOutlined />: cityData["Calgary"]}</span> </div>
                <div className="time_slot time_slot_active"><span className='city'><ClockCircleOutlined />Ottawa</span><span className='time'> { skeletonLoadingCity  === true && cityData["Ottawa"] === undefined ? <LockOutlined />: cityData["Ottawa"]}</span></div>
                <div className="time_slot time_slot_active"><span className='city'><ClockCircleOutlined />Toronto</span><span className='time'> { skeletonLoadingCity  === true && cityData["Toronto"] === undefined ? <LockOutlined />: cityData["Toronto"]}</span></div>
                <div className="time_slot time_slot_active"><span className='city'><ClockCircleOutlined />Vancouver</span> <span className='time'> { skeletonLoadingCity  === true && cityData["Vancouver"] === undefined ? <LockOutlined />: cityData["Vancouver"]}</span></div>
                <div className="time_slot"><span className='city'><ClockCircleOutlined />Halifax</span><span className='time'> { skeletonLoadingCity  === true && cityData["Halifax"] === undefined ? <LockOutlined /> : cityData["Halifax"]}</span></div>
                <div className="time_slot"><span className='city'><ClockCircleOutlined />Montreal</span><span className='time'> { skeletonLoadingCity  === true && cityData["Montreal"] === undefined ? <LockOutlined />: cityData["Montreal"]}</span></div>
                <div className="time_slot"><span className='city'><ClockCircleOutlined />Quebec</span><span className='time'> { skeletonLoadingCity === true  && cityData["Quebec"] === undefined ? <LockOutlined /> : cityData["Quebec"]}</span></div>
              {/* </Skeleton>   */}
                <div className='divider'>
                  <Divider style={{height: '10px !important'}}/>
                </div>
                <Button size={'large'} type={'primary'} className="btn_1" onClick={e => updateCityData()}>刷新位置</Button>
                { autoUpdateCityData === true && <Button size={'large'} className="btn_2" onClick={e => stopUpdate()}>停止自动刷新 - 10Mins</Button> }
                { autoUpdateCityData === false &&<Button size={'large'} className="btn_2" onClick={e => startUpdate()}>开启自动刷新 - 10Mins</Button> }
            </div>
            <div className='rightSide'>
              <div className='rightSideTop'>
                 <div className='calender'>
                    <Calendar
                      fullscreen={false} 
                      headerRender={({value}) => {
                        const monthNumber  = value.month()
                        const date = new Date();
                        date.setMonth(monthNumber);
                        const month = date.toLocaleString('en-US', { month: 'long' });
                        return (<Typography.Title level={ mobile ? 4:3}>{month + ', ' + value.year().toString()}</Typography.Title>)
                      }} 
                    />
                 </div>
                 <div className='helloBox'>
                 <Skeleton loading={skeletonLoading} avatar={{size: 60}} paragraph={{ rows: 8, width: mobile ?[175,170,165,160,165,170,175] :[240,200,180,170,160,170,180,190]}} active >
                    {user !== null && <Typography.Title level={mobile ? 4:3}>Hello, {user.nickname !== undefined && user.nickname !== ""? user.nickname : user.fullname}!</Typography.Title> }
                    <div className='p1'>
                      感谢你的耐心和支持 ！
                    </div>
                    <div className='p2'>
                      我们正全力助你轻松拿下签证面试
                    </div>
                    <div className='p3'>
                      让我们来解放你的双手🙌🏻吧~
                    </div>
                    <Button type='primary' className="btn_change" onClick={e => handleEditOpen(e)}>修改个人信息</Button>
                  </Skeleton>
                 </div>
              </div>
              <div className='rightSideBottom'>
              <Descriptions title="签证预约情况" layout="vertical" bordered>
                <Descriptions.Item label="服务类型"><Skeleton loading={skeletonLoading} paragraph={{rows: 1}} active >{profile.package_name !== undefined ? profile.package_name : '暂无购买' }</Skeleton></Descriptions.Item>
                <Descriptions.Item label="支付状态"><Skeleton loading={skeletonLoading} paragraph={{rows: 1}} active >{profile.package_name !== undefined ? '已付款' : '暂无付款' }</Skeleton></Descriptions.Item>
                <Descriptions.Item label="服务有效期"><Skeleton loading={skeletonLoading} paragraph={{rows: 1}} active >{profile.package_name !== undefined ? '1年 或 申请预约确定' : '无信息' }</Skeleton></Descriptions.Item>
                <Descriptions.Item label="创建时间"><Skeleton loading={skeletonLoading}  active paragraph={{rows: 0}}>{user !== null ? user.create_date : '/' }</Skeleton></Descriptions.Item>
                <Descriptions.Item label="确认时间" span={2}>
                <Skeleton loading={skeletonLoading}  active paragraph={{rows: 0}} > {profile.slot_comfirm_date !== undefined ? profile.slot_comfirm_date : '/' }</Skeleton>
                </Descriptions.Item>
                <Descriptions.Item label="状态" span={2}>
                <Skeleton loading={skeletonLoading}  active paragraph={{rows: 0}} ><Badge status="processing" size="big" text={profile.package_status !== undefined && profile.package_status === 1 ? "自动监控刷位置中" : "任务已完成" } /> </Skeleton>
                </Descriptions.Item>
                <Descriptions.Item label="线路" span={1}>
                <Skeleton loading={skeletonLoading}  active paragraph={{rows: 0}} > 2线</Skeleton>
                </Descriptions.Item>
                <Descriptions.Item label="当前已监控次数"> <Skeleton loading={skeletonLoading}  active paragraph={{rows: 0}} > {profile.slot_times !== undefined ? profile.slot_times +'次' : '-' } </Skeleton></Descriptions.Item>
                <Descriptions.Item label="不符合条件次数"><Skeleton loading={skeletonLoading}  active paragraph={{rows: 0}} > {profile.slot_times !== undefined ? profile.slot_times +'次' : '-' }</Skeleton></Descriptions.Item>
                <Descriptions.Item label="监控模式"><Skeleton loading={skeletonLoading}  active paragraph={{rows: 0}} >24小时人工智能 Plus+</Skeleton></Descriptions.Item>
                <Descriptions.Item label="账号状态"><Skeleton loading={skeletonLoading}  active paragraph={{rows: 0}} >正常</Skeleton></Descriptions.Item>
                <Descriptions.Item label="签证类型"><Skeleton loading={skeletonLoading}  active paragraph={{rows: 0}} > {profile.visa_type !== undefined ? profile.visa_type : '-' }</Skeleton></Descriptions.Item>
                <Descriptions.Item label="签证城市"><Skeleton loading={skeletonLoading}  active paragraph={{rows: 0}} > {profile.city !== undefined ? profile.city : '-' }</Skeleton></Descriptions.Item>
                <Descriptions.Item label="更多信息">
                <Skeleton loading={skeletonLoading}  active paragraph={{rows: 2}} >{profile.extra_info !== undefined ? profile.extra_info : "-" }</Skeleton>
                </Descriptions.Item>
              </Descriptions>
              { skeletonLoading === false && <div className="site-statistic-demo-card">
                <Row gutter={16}>
                  <Col span={12}>
                    <Card>
                      <Statistic
                        title="监控速度已提升"
                        value={99.98}
                        precision={2}
                        valueStyle={{ color: '#1890ff' }}
                        // prefix={<ArrowUpOutlined />}
                        suffix="%"
                      />
                      <Progress percent={98} status="active" showInfo={false} />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card>
                      <Statistic
                        title="丢包错过率"
                        value={0.01}
                        precision={2}
                        valueStyle={{ color: '#cf1322' }}
                        prefix={<ArrowDownOutlined />}
                        suffix="%"
                      />
                      <Progress percent={1} status="exception" />
                    </Card>
                  </Col>
                </Row>
              </div>}
              </div>
            </div>
          </div>
        </div>
        {/* <Footer/> */}
        </React.Fragment>
    )
}

// function mapStateToProps(state) {
//     // window.wlog(state)
//     return{
//     }
    
// }

// export default connect(mapStateToProps)(Help)
export default Dashboard