import React, {useState, useEffect} from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import './index.less'
import img_event from '../../asset/img/doc_top.png'
import img_cover from '../../asset/img/doc_cover.png'
import img_corner from '../../asset/img/docs_corner.png'
// import img_corner_mobile  from '../../asset/img/weetokplus_corner_mobile.png'
import img_grey_line from '../../asset/img/grey_line.png'
import img_line from '../../asset/img/docs_line.png'
import { Modal, Table, Form, Input, Radio, Button, Select, DatePicker, Breadcrumb } from 'antd';
import { CheckCircleOutlined, HomeOutlined } from '@ant-design/icons';
// import { loadStripe } from '@stripe/stripe-js';
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer } from 'recharts'

// const stripePromise = loadStripe('pk_live_51N4wIoHrsABESZ3IQ8MEyhTqkPNefCtqGIvnecGZgoll0Sb6p88tOAUSUPKjOJWg3Lxmd5fKGGYYM8qqxwAjleDK00yj5IVGby');
export default function Index() {
    // const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('inline');
    const [addVisible, setAddVisible] = useState(false);

    // const cancelConfirm = () => {
    //     console.log('cancel')
    // }

    // const okConfirm = async (type) => {

    //     let successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamqyy"
    //     let price = 'price_1N5HgQHrsABESZ3IDSPxSfR2'

    //     if (type === "ds160") {
    //         successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamds160wd"
    //         price = 'price_1NCSl4HrsABESZ3IqpTVR5os'
    //     } else if (type === "check") {
    //         successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnacheck"
    //         price = 'price_1NCSnVHrsABESZ3I7EH8bPfk'
    //     } else if (type === "b1b2") {
    //         successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamlyqwd"
    //         price = 'price_1NCT72HrsABESZ3IlSA3EXY3'
    //     } else if (type === "f1") {
    //         successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamxqwd"
    //         price = 'price_1NCT72HrsABESZ3IlSA3EXY3'
    //     } else if (type === "h1b") {
    //         successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamgqwd"
    //         price = 'price_1NCT72HrsABESZ3IlSA3EXY3'
    //     } else {
    //         successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamzxwd"
    //         price = 'price_1NCSqVHrsABESZ3IVlN2Xdgr'
    //     }

    //     // When the customer clicks on the button, redirect them to Checkout.
    //     // const stripe = await stripePromise;
    //     // const { error } = await stripe.redirectToCheckout({
    //     //   lineItems: [{
    //     //     price: price, // Replace with the ID of your price
    //     //     quantity: 1,
    //     //   }],
    //     //   mode: 'payment',
    //     //   successUrl: successUrl,
    //     //   cancelUrl:  window.location.origin+'/payresult/cancel',
    //     // });
    // }

    // const confirmToPay = (type) => {
    //     let text = "你确定前往支付"
    //     if (type === "ds160") {
    //         text += "【 DS-160终极攻略费用 】" 
    //     } else if (type === "check") {
    //         text += "【 签证分数自测文档 】"
    //     } else if (type === "b1b2") {
    //         text += "【 商务/旅游签 - 系统性整理面试材料及攻略 】"
    //     } else if (type === "f1") {
    //         text += "【 F1 材料整理清单 】"
    //     } else if (type === "h1b") {
    //         text += "【 H1b 材料整理清单 】"
    //     } else {
    //         text += "【专业私定制咨询】"
    //     }
    //     Modal.confirm({
    //         centered: true,
    //         title: 'Confirm',
    //         icon: <CheckCircleOutlined style={{color: "#ce3636"}} />,
    //         content: text,
    //         okText: '确认',
    //         cancelText: '取消',
    //         onCancel() {
    //             cancelConfirm()
    //         },
    //         onOk(type){
    //             okConfirm(type)
    //         }
            
    //     });
    // }

    const utime = new Date().getFullYear()+ '-' + (parseInt(new Date().getMonth())+ 1).toString()+ '-' + new Date().getDate() + ' '+new Date().getHours()+':'+new Date().getMinutes()+':'+new Date().getSeconds()

        
    const columns = [
        {
          title: '地点',
          align: 'center',
          dataIndex: 'location',
          filters: [
            {
              text: 'Vancouver',
              value: 'Vancouver',
            },
            {
              text: 'Toronto',
              value: 'Toronto',
            },
            {
              text: 'Calgary',
              value: 'Calgary',
            },
            {
                text: 'Halifax',
                value: 'Halifax',
            },
            {
                text: 'Ottawa',
                value: 'Ottawa',
            },
            {
                text: 'Montreal',
                value: 'Montreal',
            },
            {
                text: 'Quebec City',
                value: 'Quebec',
            },
          ],
          // specify the condition of filtering result
          // here is that finding the name started with `value`
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ['descend'],
        },
        {
            title: '当前最早可选日期',
            dataIndex: 'date',
            align: 'center',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.date - b.date,
            onFilter: (value, record) => record.address.indexOf(value) === 0,
        },
        {
          title: '当前可选位置数量',
          dataIndex: 'slotNum',
          align: 'center',
        //   defaultSortOrder: 'descend',
          sorter: (a, b) => a.slotNum - b.slotNum,
        },
        {
            title: '刷新时间',
            dataIndex: 'utime',
            align: 'center',
        },
        {
            title: '平均更新时间 (小时)',
            dataIndex: 'waitTime',
            align: 'center',
        },
        {
            title: '平局延迟时长 （秒）',
            dataIndex: 'delayTime',
            align: 'center',
        },
        // {
        //     title: '申请加急人数',
        //     dataIndex: 'explicit',
        //     align: 'center',
        // },
        {
            title: '已订阅数',
            dataIndex: 'subscribe',
            align: 'center',
        },
      ];

    const data = [
        {
            key: '1',
            location: 'Vancouver',
            slotNum: 132,
            date: '2023-08-11',
            utime: utime,
            waitTime: 2,
            delayTime: 25,
            explicit: '2',
            address: 'New York No. 1 Lake Park',
            subscribe: 4
        },
        {
            key: '2',
            location: 'Toronto',
            slotNum: 94,
            date: '2023-09-01',
            utime: utime,
            waitTime: 22,
            delayTime: 40,
            explicit: '4',
            address: 'London No. 1 Lake Park',
            subscribe: 7
        },
        {
            key: '3',
            location: 'Ottawa',
            slotNum: 82,
            date: '2023-09-07',
            utime: utime,
            waitTime: 24,
            delayTime: 30,
            explicit: '2',
            address: 'Sidney No. 1 Lake Park',
            subscribe: 3
        },
        {
            key: '4',
            location: 'Calgary',
            slotNum: 91,
            date: '2023-10-31',
            utime: utime,
            waitTime: 18,
            delayTime: 30,
            explicit: '获取中',
            address: 'London No. 2 Lake Park',
            subscribe: 1
        },
        {
            key: '5',
            location: 'Montreal',
            slotNum: 32,
            date: '2023-12-14',
            utime: utime,
            waitTime: '32',
            delayTime: 10,
            explicit: '获取中',
            address: 'London No. 2 Lake Park',
            subscribe: 1
        },
        {
            key: '6',
            location: 'Halifax',
            slotNum: "-",
            date: '2023-08-01',
            utime: utime,
            waitTime: '-',
            delayTime: 10,
            explicit: '获取中',
            address: 'London No. 2 Lake Park',
            subscribe: '-'
        },
        {
            key: '7',
            location: 'Quebec City',
            slotNum: "-",
            date: '2023-08-01',
            utime: utime,
            waitTime: '-',
            delayTime: 10,
            explicit: '获取中',
            address: 'London No. 2 Lake Park',
            subscribe: '-'
        },
    ];
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const formItemLayout =
        formLayout === 'horizontal'
        ? {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 14,
            },
            }
        : null;

    const buttonItemLayout =
        formLayout === 'horizontal'
        ? {
            wrapperCol: {
                span: 14,
                offset: 4,
            },
            }
        : null;
    

    const showModal = (index) => {
        setAddVisible(true);
    };
    
    const handleOk = () => {
        setAddVisible(false);
    };
    const handleCancel = () => {
        setAddVisible(false);
    }

    const data1 = [
        {
          "location": "Vancouver",
          "yesterday": 20,
          "today": 11
        },
        {
          "location": "Toronto",
          "yesterday": 10,
          "today": 15
        },
        {
          "location": "Ottawa",
          "yesterday": 25,
          "today": 7
        },
        {
          "location": "Calgary",
          "yesterday": 12,
          "today": 5
        },
        {
          "location": "Montreal",
          "yesterday": 0,
          "today": 0
        },
        {
          "location": "Halifax",
          "yesterday": 0,
          "today": 0
        },
        {
          "location": "Quebec City",
          "yesterday": 2,
          "today": 3
        }
      ]

    return (
        <React.Fragment>
            <Modal title="邮件提醒订阅" width={440} centered={true} visible={addVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    layout="horizontal"
                    className="components-table-demo-control-bar"
                    style={{ marginBottom: 16 }}
                >
                <Form.Item label="地点:" name="location">
                    <Select
                        defaultValue="all"
                        style={{ width: 160 }}
                        // onChange={handleChange}
                        options={[
                            {
                                label: '全部',
                                value: 'all',
                            },
                            {
                                label: 'Vancouver',
                                value: 'Vancouver',
                            },
                            {
                                label: 'Toronto',
                                value: 'Toronto',
                            },
                            {
                                label: 'Calgary',
                                value: 'Calgary',
                            },
                            {
                                label: 'Halifax',
                                value: 'Halifax',
                            },
                            {
                                label: 'Ottawa',
                                value: 'Ottawa',
                            },
                            {
                                label: 'Montreal',
                                value: 'Montreal',
                            },
                            {
                                label: 'Quebec City',
                                value: 'Quebec',
                            },
                        ]}    
                    />
                </Form.Item>
                <Form.Item label="Visa类型" name="visa">
                    <Select
                        defaultValue="B1B2"
                        style={{ width: 180 }}
                        // onChange={handleChange}
                        options={[
                            {
                                label: '全部',
                                value: 'all',
                            },
                            {
                                label: 'B1 / B2 商务 / 旅游签',
                                value: 'B1B2',
                            },
                            {
                                label: 'J1/J2',
                                value: 'J1J2',
                            },
                            {
                                label: 'F1 / F2',
                                value: 'Calgary',
                            },
                            {
                                label: 'H1B',
                                value: 'H1B',
                            },
                            {
                                label: 'L1 / L2',
                                value: 'L1L2',
                            },
                            {
                                label: 'O1 / O2 / O3',
                                value: 'O1O2O3',
                            },
                        ]}    
                    />
                </Form.Item>
                <Form.Item label="刷新频率" name="refresh">
                    <Radio.Group value={formLayout} defaultValue="3">
                    <Radio.Button value="1">1分钟</Radio.Button>
                    <Radio.Button value="3">3分钟</Radio.Button>
                    <Radio.Button value="5">5分钟</Radio.Button>
                    <Radio.Button value="-">自定义</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="起始日期">
                    <DatePicker placeholder="选择日期" style={{ width: 170 }}/>
                </Form.Item>
                <Form.Item label="截止日期">
                    <DatePicker placeholder="选择日期" style={{ width: 170 }}/>
                </Form.Item>
                <Form.Item label="邮箱地址">
                    <Input style={{ width: 220 }} placeholder="email@gmail.com" />
                </Form.Item>
                <Form.Item label="确认邮箱地址">
                    <Input style={{ width: 220 }} placeholder="email@gmail.com" />
                </Form.Item>
                {/* <Form.Item label="Field B">
                    <Input placeholder="input placeholder" />
                </Form.Item> */}
                </Form>
            </Modal>
            <Header blank={true}/>
            <div className="appt_container">
                <div className='table_container'>
                        <div className='table_breadcrumb'>
                            <Breadcrumb>
                                <Breadcrumb.Item href="">
                                <HomeOutlined />
                                <span>内测功能</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item href="">
                                <span>加拿大各城市美国签证预约时间</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>测试版 v2</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div className='table_chart'>
                            <div className='table_chart_title'>
                                城市放位次数:
                            </div>
                            <BarChart width={1380} height={320} data={data1} >
                                <XAxis dataKey="location" >
                                </XAxis>
                                <YAxis />
                                {/* <Tooltip /> */}
                                <Bar dataKey="yesterday" fill="#701919" />
                                <Bar dataKey="today" fill="#8884d8" />
                            </BarChart>
                        </div>
                        <div className='table_h'>
                            
                        <Form
                            layout="inline"
                            className="components-table-demo-control-bar"
                            style={{ marginBottom: 16 }}
                        >
                        <Form.Item label="地点" name="location">
                            <Select
                                defaultValue="all"
                                style={{ width: 120 }}
                                // onChange={handleChange}
                                options={[
                                    {
                                        label: '全部',
                                        value: 'all',
                                    },
                                    {
                                        label: 'Vancouver',
                                        value: 'Vancouver',
                                    },
                                    {
                                        label: 'Toronto',
                                        value: 'Toronto',
                                    },
                                    {
                                        label: 'Calgary',
                                        value: 'Calgary',
                                    },
                                    {
                                        label: 'Halifax',
                                        value: 'Halifax',
                                    },
                                    {
                                        label: 'Ottawa',
                                        value: 'Ottawa',
                                    },
                                    {
                                        label: 'Montreal',
                                        value: 'Montreal',
                                    },
                                    {
                                        label: 'Quebec City',
                                        value: 'Quebec',
                                    },
                                ]}    
                            />
                        </Form.Item>
                        <Form.Item label="Visa类型" name="visa">
                            <Select
                                defaultValue="B1B2"
                                style={{ width: 180 }}
                                // onChange={handleChange}
                                options={[
                                    {
                                        label: '全部',
                                        value: 'all',
                                    },
                                    {
                                        label: 'B1 / B2 商务 / 旅游签',
                                        value: 'B1B2',
                                    },
                                    {
                                        label: 'J1/J2',
                                        value: 'J1J2',
                                    },
                                    {
                                        label: 'F1 / F2',
                                        value: 'Calgary',
                                    },
                                    {
                                        label: 'H1B',
                                        value: 'H1B',
                                    },
                                    {
                                        label: 'L1 / L2',
                                        value: 'L1L2',
                                    },
                                    {
                                        label: 'O1 / O2 / O3',
                                        value: 'O1O2O3',
                                    },
                                ]}    
                            />
                        </Form.Item>
                        <Form.Item label="刷新频率" name="refresh">
                            <Radio.Group value={formLayout} defaultValue="3">
                            <Radio.Button value="1">1分钟</Radio.Button>
                            <Radio.Button value="3">3分钟</Radio.Button>
                            <Radio.Button value="5">5分钟</Radio.Button>
                            {/* <Radio.Button value="-">自定义</Radio.Button> */}
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="起始">
                            <DatePicker placeholder="选择日期" style={{ width: 160 }}/>
                        </Form.Item>
                        <Form.Item label="截止">
                            <DatePicker placeholder="选择日期" style={{ width: 160 }}/>
                        </Form.Item>
                        <Form.Item {...buttonItemLayout}>
                            <Button type="primary">自动刷新</Button>
                        </Form.Item>
                        {/* <Form.Item {...buttonItemLayout}>
                            <Button disabled type="primary">手动刷新</Button>
                        </Form.Item> */}
                        {/* <Form.Item label="邮箱地址">
                            <Input placeholder="test@gmail.com" />
                        </Form.Item> */}
                        {/* <Form.Item label="Field B">
                            <Input placeholder="input placeholder" />
                        </Form.Item> */}
                        <Form.Item {...buttonItemLayout}>
                            <Button type="primary" onClick={showModal}>添加邮件订阅</Button>
                        </Form.Item>
                        </Form>
                        </div>
                        <div className='table_c'>
                            <Table columns={columns} dataSource={data} onChange={onChange} pagination={false} />

                        </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    )
}