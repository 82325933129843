import React, { useEffect, useState } from 'react';
import { Result } from 'antd';
import whatsapp from '../../asset/img/whatsapp.png'
import './style_sub.less';
import Header from '../../components/header'


export default function Success() {
  const [planName,setPlanName] = useState("急速包周")
  const week = 'a4dnu3kxnaweekoksub'
  const month = 'a4dnu3kxnamonthoksub'
  const season= 'a4dnu3kxnaseasonoksub'

  useEffect(() => {
    const query = window.location.search
    if (query === "") {
      window.location.replace(window.location.origin+"/payresult/cancel?type=2")    
    }
    const tk = query.split("?tk=")[1]
    if (tk === week){
      setPlanName('急速包周(送2天)')
    }
    else if (tk === month) {
      setPlanName('安心包月(送10天)')
    } 
    else if (tk === season) {
      setPlanName('畅享包季(送20天)')
    } 
    else{
      window.location.replace(window.location.origin+"/payresult/cancel")
    }
  }, [])

  return (
    <>
      <Header blank={true}/>
      <div className="payresult_wrap">
        <Result
        className="result_wrap"
        status="success"
        title="支付成功"
      >
        <div className="new_res">
          <p>亲爱的小伙伴，</p>
          <p>恭喜你已经成功支付 - <span style={{color: 'red', fontSize: 15,fontWeight:500}}>{planName}</span> - 的提醒订阅。为了确保你的服务正常使用，请按照下面步骤指示来开始你的任务！</p>
          <p style={{marginTop:'30px'}}><strong>步骤一：</strong> 请使用 whatsApp添加你的私人小助手。扫下方二维码：</p>
          <p>( 若没有可以自行去AppStore商店【苹果】或者谷歌商城【安卓】下载 )</p>
          <img src={whatsapp} className="img_qrcode" alt="" />
          <p style={{marginTop:'30px'}}><strong>步骤二：</strong> 拍照提供这个付款成功页面或者提供付款人名字</p>
          <p style={{marginTop:'30px'}}><strong>步骤三：</strong> 待确认后，提供你接收提醒的邮箱 📮</p>                  
          <p style={{marginTop:'30px'}}>如遇到问题联系：support@caex-consulting.com <br/><br/>CAEX 祝你一切顺利, 早日获得心仪位置！</p>
        </div>
      </Result> 
      </div>
    </>
  )
}