import React, {useState, useEffect} from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import './index.less'
import sub_bg from '../../asset/img/sub_bg.png'
import sub_bg_2 from '../../asset/img/sub_bg_2.png'
import free from '../../asset/img/free.png'
import img_cover from '../../asset/img/doc_cover.png'
import img_corner from '../../asset/img/docs_corner.png'
// import img_corner_mobile  from '../../asset/img/weetokplus_corner_mobile.png'
import img_grey_line from '../../asset/img/grey_line.png'
import img_line from '../../asset/img/docs_line.png'
import email_logo_sub from '../../asset/img/email_logo_sub.png'
import check from '../../asset/img/check.png'
import unlimited from '../../asset/img/unlimited.png'
import pro from '../../asset/img/pro.png'
import ding from '../../asset/img/ding.png'
import question_mark from '../../asset/img/question_mark.png'
import summer_sale_tag from '../../asset/img/summer_sale_tag.png'
import { Modal, Button, InputNumber, Tag, Checkbox, Collapse, Alert } from 'antd';
import { WhatsAppOutlined, BellOutlined  } from '@ant-design/icons';
import { loadStripe } from '@stripe/stripe-js';
import PlanConsultQrcode from '../../components/subscribe/consult_qrcode'
import { useLocaleContext } from '../../utils/context'
import { Text } from '../../utils/locale'
import { Helmet } from 'react-helmet';

const stripePromise = loadStripe('pk_live_51N4wIoHrsABESZ3IQ8MEyhTqkPNefCtqGIvnecGZgoll0Sb6p88tOAUSUPKjOJWg3Lxmd5fKGGYYM8qqxwAjleDK00yj5IVGby');
export default function Index() {
  const { Panel } = Collapse;  
    const [count, SetCount] = useState(3)
    const [planSelect, SetPlanSelect] = useState(2)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [num, setNum] = useState(1);
    const [checkUnderstand, setCheckUnderstand] = useState(false);
    const [alert, SetAlert] = useState(false);
    const [refVisibleConsult, setRefVisibleConsult] = useState(false);
    const { locale } = useLocaleContext()

    const cancelConfirm = () => {
        console.log('cancel')
    }
    const hiddeTidoLogo = (iframeContent) => {
        console.log('点击了')
        setTimeout(()=> {
            var element = document.querySelector('iframe[title="Tidio Chat"]');
            var iframeContent = element.contentDocument || element.contentWindow.document;
            var elementChild = iframeContent.querySelector('a[aria-label="Powered by Tidio."]');
            console.log(elementChild)
            // const body = iframeContent.querySelector('body');
            if (elementChild) {
                elementChild.parentNode.style.display = 'none'
            }
        },500)
    }

    useEffect(() => {
        setTimeout(() => {
            if (count > 0) {
                var element = document.querySelector('iframe[title="Tidio Chat"]');
                // console.log(element)
                if (element === null || element === undefined) {
                    SetCount(count-1)
                } else {
                    console.log(element)
                    var iframeContent = element.contentDocument || element.contentWindow.document;
                    console.log(iframeContent)
                    // element.addEventListener('click',hiddeTidoLogo)
                    const body = iframeContent.querySelector('body');
                    body.addEventListener('click',hiddeTidoLogo)
                }
                
            }
        }, 2000);
    },[count])

    const okConfirm = async (type) => {

        let successUrl = window.location.origin+"/payresult/success_subscribe?tk=a4dnu3kxnamonthoksub"
        let price = 'price_1NhGFlHrsABESZ3IondyLEDs'

        if (type === 1) {
            successUrl = window.location.origin+"/payresult/success_subscribe?tk=a4dnu3kxnaweekoksub"
            price = 'price_1NhG9VHrsABESZ3Igj5i5NIp'
        } else if (type === 2) {
            successUrl = window.location.origin+"/payresult/success_subscribe?tk=a4dnu3kxnamonthoksub"
            price = 'price_1NhGFlHrsABESZ3IondyLEDs'
        } else if (type === 3) {
            successUrl = window.location.origin+"/payresult/success_subscribe?tk=a4dnu3kxnaseasonoksub"
            price = 'price_1NhGHAHrsABESZ3IetsBjFUD'
        } else {
            successUrl = window.location.origin+"/payresult/success_subscribe?tk=a4dnu3kxnamonthoksub"
            price = 'price_1NhGFlHrsABESZ3IondyLEDs'
        }

        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          lineItems: [{
            price: price, // Replace with the ID of your price
            quantity: num,
          }],
          mode: 'payment',
          successUrl: successUrl,
          cancelUrl:  window.location.origin+'/payresult/cancel?type=2',
        });
    }

    const openSubConfirm = (type) => {
        SetPlanSelect(type)
        console.log(isModalOpen)
        setIsModalOpen(true);
    }
    
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const handleOk = () => {
      // okConfirm();
      if (checkUnderstand === false) {
        SetAlert(true)
        return null
      } else {
        SetAlert(false)
        console.log(planSelect)
        okConfirm(planSelect)
      }
    };

    const onNumChange = (value) => {
      setNum(value)
    }

    const onCheckChange = (e) => {
      console.log(`checked = ${e.target.checked}`);
      setCheckUnderstand(e.target.checked)
      SetAlert(!e.target.checked)
    };

    const openPolicy = () => {
      // window.open("https://www.caex-consulting.com/subscription_policy")
      window.open("https://www.caex-consulting.com/subscription_policy")
    }

    const changeVisibleConsult = e => {
      setRefVisibleConsult(e)
    }

    const openConsult = e => {
      setRefVisibleConsult(true)
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>提醒订阅功能</title>
                <meta
                name="description"
                content="高效放位精准提示，筛查月内面签空位，各大签证类型，各类人群需求，全部尽收眼底"
                />
                <meta
                name="keywords"
                content="美签预约，美签免面签申请，美签申请，美签代办，美签加急"
                />
            </Helmet>
            <Header/>
            <PlanConsultQrcode refVisible={refVisibleConsult} changeVisible={changeVisibleConsult}/>
            <Modal
              width={450}
              open={isModalOpen}
              onCancel={handleCancel}
              onOk={handleOk}
              title={ <span style={{fontWeight: 700}}><BellOutlined style={{fontSize: 18,marginRight:5}}/>订阅服务确认</span>}
              centered={true}
              okText="前往支付"
              cancelText="返回"
              >
                    <div>
                    { planSelect === 1 && <span>已选择类型： <Tag color="red" style={{fontSize: 13}}>安心包月 (7 + 2) 天</Tag> </span>}
                    { planSelect === 2 && <span>已选择类型： <Tag color="red" style={{fontSize: 13}}>安心包月 (31 + 10) 天</Tag> </span>}
                    { planSelect === 3 && <span>已选择类型： <Tag color="red" style={{fontSize: 13}}>安心包月 (93 + 20) 天</Tag> </span>}
                    </div>
                    <div className='num-count'>
                      <span>数量：</span><InputNumber min={1} max={12} defaultValue={1} onChange={onNumChange} />
                    </div>
                    <div className='num-count'>
                      <span>备注：系统会自动转换成<strong> CAD </strong>结算</span>
                    </div>
                    <div className='num-count'>
                      <Checkbox onChange={onCheckChange}>I have read and understood the <a href="javascript:void(0);" onClick={ e => openPolicy()}>Privacy Policy and the Terms of Use</a></Checkbox>
                    </div>
                    { checkUnderstand === false && alert === true && <Alert message="请勾选已读 Privacy Policy and the Terms of Use" type="warning" showIcon closable={false} />}
            </Modal>
            <div className="sub_container">
                <img src={sub_bg} alt="backgroundImg" className='sub_bg'></img>
                <div className="sub_header">
                  <h2 className="sub_title"><img src={ding} alt="ding"></img>{Text.indexS0[locale]}</h2>
                  <p className="sub_des">
                      {Text.indexS1[locale]}
                  </p> 
                </div>
                <div className="sub_plan">
                    <div className='sub_card'>
                        <div>
                          <div className='sub_card_top'>
                            <img src={email_logo_sub} alt="email_logo_sub" />
                            <h4>{Text.indexS2[locale]} <span>{Text.indexS3[locale]}</span></h4>
                            <p>{Text.indexS4[locale]}</p>
                            <p className='sub_price'><sup>$</sup><span>1.1 </span> <small>USD per day</small></p>
                            <p>$9.99 for weekly notification</p>
                            <hr/>
                          </div>
                          <ul>
                            <li>
                              <div><img src={check} alt="" /></div>
                              <p>{Text.indexS5[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check} alt="" /></div>
                              <p>{Text.indexS6[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS7[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS8[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS9[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS10[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check} alt="" /></div>
                              <p>{Text.index11[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS12[locale]}</p>
                            </li>
                          </ul>
                        </div>
                        <Button
                        // onClick={e => openConsult(e)}
                        // onClick={showModal}
                        onClick={e => openSubConfirm(1)}
                        type="primary"
                        data-seed="pkg-resume-consult"
                        className="subscribeBtn">{Text.indexS13[locale]}
                        </Button>
                    </div>
                    <div className='sub_card'>
                        <img className='summer_sale_tag' src={summer_sale_tag} alt="summer_sale_tag"></img>
                        <div>
                          <div className='sub_card_top'>
                            <img src={email_logo_sub} alt="email_logo_sub" />
                            <h4>{Text.indexS14[locale]} <span>{Text.indexS15[locale]}</span></h4>
                            <p>{Text.indexS16[locale]}</p>
                            <p className='sub_price'><sup>$</sup><span>0.73 </span> <small>USD per day</small></p>
                            <p>$29.99 for monthly notification</p>
                            <hr/>
                          </div>
                          <ul>
                            <li>
                              <div><img src={check} alt="" /></div>
                              <p>{Text.indexS17[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check} alt="" /></div>
                              <p>{Text.indexS6[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS7[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS8[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS9[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS10[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check} alt="" /></div>
                              <p>{Text.index11[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS12[locale]}</p>
                            </li>
                          </ul>
                        </div>
                        <Button
                        // onClick={e => openConsult(e)}
                        // onClick={showModal}
                        onClick={e => openSubConfirm(2)}
                        type="primary"
                        data-seed="pkg-resume-consult"
                        className="subscribeBtn">{Text.indexS13[locale]}
                        </Button>
                    </div>
                    <div className='sub_card'>
                        <div>
                          <div className='sub_card_top'>
                            <img src={email_logo_sub} alt="email_logo_sub" />
                            <h4>{Text.indexS18[locale]} <span>{Text.indexS19[locale]}</span></h4>
                            <p>{Text.indexS21[locale]}</p>
                            <p className='sub_price'><sup>$</sup><span>0.57 </span> <small>USD per day</small></p>
                            <p>$64.99 for monthly notification</p>
                            <hr/>
                          </div>
                          <ul>
                            <li>
                              <div><img src={check} alt="" /></div>
                              <p>{Text.indexS20[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check} alt="" /></div>
                              <p>{Text.indexS6[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS7[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS8[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS9[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS10[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check} alt="" /></div>
                              <p>{Text.index11[locale]}</p>
                            </li>
                            <li>
                              <div><img src={check}  alt="" /></div>
                              <p>{Text.indexS12[locale]}</p>
                            </li>
                          </ul>
                        </div>
                        <Button
                        onClick={e => openSubConfirm(3)}
                        // onClick={showModal}
                        type="primary"
                        data-seed="pkg-resume-consult"
                        className="subscribeBtn">{Text.indexS13[locale]}
                        </Button>
                    </div>
                </div>
                <div className="faq">
                  <div className="title">
                      <h2 className="sub_title"><img src={question_mark} alt="question_mark"></img>{Text.indexS22[locale]}</h2>
                      <p className="sub_des">
                        {Text.indexS23[locale]}
                      </p> 
                  </div>
                  <Collapse bordered={false} className="faq_collapse">
                      <Panel header={<div data-seed="pkg-resume-faq01"> {Text.indexS24[locale]}</div>}>
                          <p className="faq_answer"> {Text.indexS25[locale]}</p>
                      </Panel>
                      <Panel header={<div data-seed="pkg-resume-faq02">  {Text.indexS26[locale]}</div>}>
                          <p className="faq_answer"> {Text.indexS27[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq03">  {Text.indexS28[locale]}</div>}>
                          <p className="faq_answer"> {Text.indexS29[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq04">  {Text.indexS30[locale]}</div>}>
                          <p className="faq_answer"> {Text.indexS31[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq04">  {Text.indexS32[locale]}</div>}>
                          <p className="faq_answer"> {Text.indexS33[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq04">{Text.indexS34[locale]} </div>}>
                          <p className="faq_answer">{Text.indexS35[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq04"> {Text.indexS36[locale]}</div>}>
                          <p className="faq_answer">{Text.indexS37[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq04"> {Text.indexS38[locale]}</div>}>
                          <p className="faq_answer">{Text.indexS39[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq04"> {Text.indexS40[locale]}</div>}>
                          <p className="faq_answer"> {Text.indexS41[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq04"> {Text.indexS42[locale]} </div>}>
                          <p className="faq_answer">{Text.indexS43[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq04"> {Text.indexS44[locale]}</div>}>
                          <p className="faq_answer">{Text.indexS45[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq04"> {Text.indexS46[locale]}</div>}>
                          <p className="faq_answer"> {Text.indexS47[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq04"> {Text.indexS48[locale]} </div>}>
                          <p className="faq_answer"> {Text.indexS49[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq04"> {Text.indexS50[locale]}</div>}>
                          <p className="faq_answer"> {Text.indexS51[locale]}</p>
                      </Panel>
                      <Panel
                          header={<div data-seed="pkg-resume-faq04">{Text.indexS52[locale]}</div>}>
                          <p className="faq_answer">{Text.indexS53[locale]}</p>
                      </Panel>
                      {/* <Panel
                          header={<div data-seed="pkg-resume-faq04"> {Text.indexS54[locale]}</div>}>
                          <p className="faq_answer">{Text.indexS55[locale]}</p>
                      </Panel> */}
                  </Collapse>
                </div>
                <div className='need_help'>
                  <div className="title">
                      <h2 className="sub_title">&nbsp;{Text.indexS54[locale]}</h2>
                      <p className="sub_des">
                      {Text.indexS55[locale]}
                      </p> 
                      <Button type="link" size="large" onClick={e => openConsult(e)}  icon={<WhatsAppOutlined style={{fontSize: 23}}/>}>{Text.indexS56[locale]}</Button>
                  </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    )
}