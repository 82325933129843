import React, {useState, useEffect} from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import './index.less'
import img_event from '../../asset/img/xhs_logo1.png'
import xhspost0 from '../../asset/img/xhs/0.jpg'
import xhspost1 from '../../asset/img/xhs/1.jpg'
import xhspost2 from '../../asset/img/xhs/2.jpg'
import xhspost3 from '../../asset/img/xhs/3.jpg'
import xhspost4 from '../../asset/img/xhs/4.jpg'
import xhspost5 from '../../asset/img/xhs/5.jpg'
import xhspost6 from '../../asset/img/xhs/6.jpg'
import xhspost7 from '../../asset/img/xhs/7.jpg'
import xhspost8 from '../../asset/img/xhs/8.jpg'
import xhspost9 from '../../asset/img/xhs/9.jpg'
import xhspost10 from '../../asset/img/xhs/10.jpg'
import xhspost11 from '../../asset/img/xhs/11.jpeg'
import xhspost12 from '../../asset/img/xhs/12.jpg'
import xhspost13 from '../../asset/img/xhs/13.jpg'
import xhspost14 from '../../asset/img/xhs/14.jpg'
import xhspost15 from '../../asset/img/xhs/15.jpg'
import xhspost16 from '../../asset/img/xhs/16.jpg'
import xhspost17 from '../../asset/img/xhs/17.jpg'
import xhspost18 from '../../asset/img/xhs/18.jpg'
import xhspost19 from '../../asset/img/xhs/19.jpg'
import xhspost20 from '../../asset/img/xhs/20.jpg'
import xhspost21 from '../../asset/img/xhs/21.jpg'
import xhspost22 from '../../asset/img/xhs/22.jpg'
import xhspost23 from '../../asset/img/xhs/23.jpg'
import xhspost24 from '../../asset/img/xhs/24.jpg'
import xhspost25 from '../../asset/img/xhs/25.jpg'
import xhspost26 from '../../asset/img/xhs/26.jpg'
import xhspost27 from '../../asset/img/xhs/27.jpg'
import xhspost28 from '../../asset/img/xhs/28.jpg'
import xhspost29 from '../../asset/img/xhs/29.jpg'
import xhspost30 from '../../asset/img/xhs/30.jpg'
import xhspost31 from '../../asset/img/xhs/31.jpg'
import xhspost32 from '../../asset/img/xhs/32.jpg'
import xhspost33 from '../../asset/img/xhs/33.jpg'
import xhspost34 from '../../asset/img/xhs/34.jpg'
import xhspost35 from '../../asset/img/xhs/35.jpg'
import xhspost36 from '../../asset/img/xhs/36.jpg'
import xhspost37 from '../../asset/img/xhs/37.jpg'
import xhspost38 from '../../asset/img/xhs/38.jpg'
import xhspost39 from '../../asset/img/xhs/39.jpg'
import xhspost40 from '../../asset/img/xhs/40.jpg'
import xhspost41 from '../../asset/img/xhs/41.jpg'
import xhspost42 from '../../asset/img/xhs/42.jpg'
import xhspost43 from '../../asset/img/xhs/43.jpg'
import xhspost44 from '../../asset/img/xhs/44.jpg'
import xhspost45 from '../../asset/img/xhs/45.jpg'
import xhspost46 from '../../asset/img/xhs/46.jpg'
import xhspost47 from '../../asset/img/xhs/47.jpg'
import xhspost48 from '../../asset/img/xhs/48.jpg'
import xhspost49 from '../../asset/img/xhs/49.jpg'
import xhspost50 from '../../asset/img/xhs/50.jpg'
import xhspost51 from '../../asset/img/xhs/51.jpg'
import xhspost52 from '../../asset/img/xhs/52.jpg'
import xhspost53 from '../../asset/img/xhs/53.jpg'
import xhspost54 from '../../asset/img/xhs/54.jpg'
import xhspost55 from '../../asset/img/xhs/55.jpg'
import xhspost56 from '../../asset/img/xhs/56.jpg'
import xhspost57 from '../../asset/img/xhs/57.jpg'
import xhspost58 from '../../asset/img/xhs/58.jpg'
import xhspost59 from '../../asset/img/xhs/59.jpg'
import xhspost60 from '../../asset/img/xhs/60.jpg'
import xhspost61 from '../../asset/img/xhs/61.jpg'
import xhspost62 from '../../asset/img/xhs/62.jpg'
import xhspost63 from '../../asset/img/xhs/63.jpg'
import xhspost64 from '../../asset/img/xhs/64.jpg'
import xhspost65 from '../../asset/img/xhs/65.jpg'
import xhspost66 from '../../asset/img/xhs/66.jpg'
import xhspost67 from '../../asset/img/xhs/67.jpg'
import xhspost68 from '../../asset/img/xhs/68.jpg'
import xhspost69 from '../../asset/img/xhs/69.jpg'
import xhspost70 from '../../asset/img/xhs/70.jpg'
import xhspost71 from '../../asset/img/xhs/71.jpg'
import xhspost72 from '../../asset/img/xhs/72.jpg'
import xhspost73 from '../../asset/img/xhs/73.jpg'
import xhspost74 from '../../asset/img/xhs/74.jpg'
import xhspost75 from '../../asset/img/xhs/75.jpg'
import xhspost76 from '../../asset/img/xhs/76.jpg'
import xhspost77 from '../../asset/img/xhs/77.jpg'
import xhspost78 from '../../asset/img/xhs/78.jpg'
import xhspost79 from '../../asset/img/xhs/79.jpg'
import xhspost80 from '../../asset/img/xhs/80.jpg'
import xhspost81 from '../../asset/img/xhs/81.jpg'
import xhspost82 from '../../asset/img/xhs/82.jpg'
import xhspost83 from '../../asset/img/xhs/83.jpg'
import xhspost84 from '../../asset/img/xhs/84.jpg'
import xhspost85 from '../../asset/img/xhs/85.jpg'
import xhspost1000 from '../../asset/img/xhs/1000.jpg'
import { DownOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Helmet } from 'react-helmet';

export default function Index() {

  const [showMore2, SetShowMore2] = useState(false)
  const [showMore1, SetShowMore1] = useState(false)

  const post_img_list_3 = [
    { img: xhspost1000, link: 'http://xhslink.com/oHO1Dr'},
    { img: xhspost85, link: 'http://xhslink.com/EoW1Dr'},
    { img: xhspost84, link: 'http://xhslink.com/xvk2Dr'},
    { img: xhspost83, link: 'http://xhslink.com/p1r2Dr'},
    { img: xhspost82, link: 'http://xhslink.com/o9v2Dr'},
    { img: xhspost81, link: 'http://xhslink.com/yJC2Dr'},
    { img: xhspost80, link: 'http://xhslink.com/BgH2Dr'},
    { img: xhspost79, link: ''},
    { img: xhspost78, link: ''},
    { img: xhspost77, link: ''},
    { img: xhspost76, link: ''},
    { img: xhspost75, link: ''},
    { img: xhspost74, link: ''},
    { img: xhspost73, link: ''},
    { img: xhspost72, link: ''},
    { img: xhspost71, link: ''},
    { img: xhspost70, link: ''},
    { img: xhspost69, link: ''},
    { img: xhspost68, link: ''},
    { img: xhspost67, link: ''},
    { img: xhspost66, link: ''},
    { img: xhspost65, link: ''},
    { img: xhspost64, link: ''},
    { img: xhspost63, link: ''},
    { img: xhspost62, link: ''},
    { img: xhspost61, link: ''},
    { img: xhspost60, link: ''},
    { img: xhspost59, link: ''},
  ]

  const post_img_list_2 = [
    { img: xhspost58, link: ''},
    { img: xhspost57, link: ''},
    { img: xhspost56, link: ''},
    { img: xhspost55, link: ''},
    { img: xhspost54, link: ''},
    { img: xhspost53, link: ''},
    { img: xhspost52, link: ''},
    { img: xhspost51, link: ''},
    { img: xhspost50, link: ''},
    { img: xhspost49, link: ''},
    { img: xhspost48, link: ''},
    { img: xhspost47, link: ''},
    { img: xhspost46, link: ''},
    { img: xhspost44, link: ''},
    { img: xhspost45, link: ''},
    { img: xhspost43, link: ''},
    { img: xhspost42, link: ''},
    { img: xhspost41, link: ''},
    { img: xhspost40, link: ''},
    { img: xhspost39, link: ''},
    { img: xhspost38, link: ''},
    { img: xhspost37, link: ''},
    { img: xhspost36, link: ''},
    { img: xhspost35, link: ''},
    { img: xhspost34, link: ''},
    { img: xhspost33, link: ''},
    { img: xhspost32, link: ''},
    { img: xhspost31, link: ''},
    { img: xhspost30, link: ''},
    { img: xhspost29, link: ''},
    { img: xhspost28, link: ''},
  ]

  const post_img_list_1 = [
    { img: xhspost27, link: ''},
    { img: xhspost26, link: ''},
    { img: xhspost25, link: ''},
    { img: xhspost24, link: ''},
    { img: xhspost23, link: ''},
    { img: xhspost22, link: ''},
    { img: xhspost21, link: ''},
    { img: xhspost20, link: ''},
    { img: xhspost19, link: ''},
    { img: xhspost18, link: ''},
    { img: xhspost17, link: ''},
    { img: xhspost16, link: ''},
    { img: xhspost15, link: ''},
    { img: xhspost14, link: ''},
    { img: xhspost13, link: ''},
    { img: xhspost12, link: ''},
    { img: xhspost11, link: ''},
    { img: xhspost10, link: ''},
    { img: xhspost9, link: ''},
    { img: xhspost8, link: ''},
    { img: xhspost7, link: ''},
    { img: xhspost6, link: ''},
    { img: xhspost5, link: ''},
    { img: xhspost4, link: ''},
    { img: xhspost3, link: ''},
    { img: xhspost2, link: ''},
    { img: xhspost1, link: ''},
    { img: xhspost0, link: ''},
  ]

  const show_more1 = () => {
    SetShowMore1(true)
  }
  const show_more2 = () => {
    SetShowMore2(true)
  }

  const openxhs = (link) => {
    if (link === "") {
      Modal.info({
        title: '温馨提示',
        content: (
          <div>
            <p>链接还未同步更新...请自行前往小红书查询</p>
          </div>
        ),
      });
    } else {
      window.open(link)
    }
  }

  return (
    <React.Fragment>
            <Header/>
            <Helmet>
                <title>实用攻略分享</title>
                <meta
                name="description"
                content="各大高频美签问题，各类精品总结，全方位解决你的疑问"
                />
                <meta
                name="keywords"
                content="美签攻略，美签咨询，美签预约，美签免面签申请，美签申请，美签代办，美签加急"
                />
            </Helmet>
            <div className="xhs_container">
              <div className="xhs_header">
                      <img src={img_event} alt="" className="xhs_img_event" onClick={ e => openxhs("https://www.xiaohongshu.com/user/profile/641a3fd70000000011022454?xhsshare=CopyLink&appuid=641a3fd70000000011022454&apptime=1687930954")} />
                      <div className="xhs_title_con">
                          <h2 className="xhs_title">小红书宝藏分享</h2>
                          <div className="xhs_des">
                              各大高频美签问题，各类精品总结，全方位解决你的疑问
                          </div>
                      </div>
              </div>
              <div className="xhs_main-container">
                  { post_img_list_3.map( (item) => {
                    return  <div className='postCard' onClick={ e => openxhs(item.link)}>
                        <img src={item.img} alt="" />
                    </div>
                  })}
                  { showMore2 && post_img_list_2.map( (item) => {
                    return  <div className='postCard'>
                        <img src={item.img} alt="" />
                    </div>
                  })}
                  { showMore1 && post_img_list_1.map( (item) => {
                    return  <div className='postCard'>
                        <img src={item.img} alt="" />
                    </div>
                  })}
              </div>
              { showMore2 === false &&<div className="xhs_more" onClick={ e => show_more2()}>
                <span><DownOutlined/> 显示更多</span>
              </div>}
              { showMore1 === false && showMore2 === true &&<div className="xhs_more" onClick={ e => show_more1()}>
                <span><DownOutlined/> 显示更多</span>
              </div>}
            </div>
            <Footer/>
    </React.Fragment>
  )
}