// import styles from './index.less'
import React from 'react';
import './index.less'
import Header from '../../components/header'
import Footer from '../../components/footer'

function Policy () {
  return (
      <React.Fragment>
      <Header blank={true}/>
      <div className='policyContainer'>
        {/* <title>User Service No Refund Agreement</title> */}
        <h1>User Service No Refund Agreement</h1>

        <p>This User Service No Refund Agreement ("Agreement") is a legally binding contract between CAEX Canada Express Consulting Co. ("Company") and the user ("User") regarding the purchase of services provided by the Company. By using our services, clicking "Agree" on the website, or engaging in any other form of acceptance, the User acknowledges and agrees to be bound by all the terms and conditions set forth in this Agreement.</p>

        <h2>Please carefully review the following terms:</h2>

        <ol>
          <li><strong>Refund Policy:</strong> The User acknowledges that the services provided by the Company are non-refundable. Once the services have been purchased and payment has been made, no refunds will be issued, unless otherwise stated explicitly in a separate agreement or required by applicable law.</li>
          
          <li><strong>Service Description:</strong> The User acknowledges that it is their responsibility to fully understand the features and functionalities of the services offered by the Company. The Company provides trial versions for the User to evaluate and assess the services before making a purchase.</li>
          
          <li><strong>Purchase and Usage:</strong> After evaluating the trial version, the User has the right to choose the appropriate service package or customized services offered by the Company. Upon offline or online purchase, or any other mutually agreed method of purchase, the User will be granted access to the respective services according to their selected package, including account activation, environment configuration, user settings, and other related services.</li>
          
          <li><strong>User Obligations:</strong> The User has the obligation to carefully review and select the services and packages that best suit their needs. The Company offers services that may include free (permanently free), monthly/yearly subscription fees, and customized services. The specific services and pricing will be determined by the information published by the Company on its official platform. The Company reserves the right to modify and change the pricing structure and service offerings as deemed necessary.</li>
          
          <li><strong>Renewal and Termination:</strong> The User acknowledges and agrees that they must provide clear notification to the Company at least two business days prior to the expiration of their purchased service package if they do not wish to renew. Failure to do so will result in automatic renewal of the service package for another billing cycle. The Company reserves the right to deduct the corresponding fees for the next billing cycle from the User's account balance without requiring verification of account passwords, payment passwords, or SMS verification codes.</li>
          
          <li><strong>Disputes and Inquiries:</strong> In the event of any disputes or dissatisfaction with an order, the User agrees to contact the Company's customer service or send an email to support@caex-consulting.com for resolution.</li>
        </ol>

        <p>By accepting this Agreement, the User acknowledges that they have read, understood, and agreed to all the terms and conditions outlined herein regarding the non-refundable nature of the services provided by the Company.</p>

        <p>CAEX Canada Express Consulting Co.</p>

        <h1>User's Conduct</h1>

        <h2>You warrant and declare that while you are using caex-consulting.com you will agree to and act according to the following rules:</h2>

        <p>User will not make arrangements outside of the CAEX Canada Express Consulting platform to have paid relations or any contact with Sharers contacted through caex-consulting.com without written consent from CAEX Canada Express Consulting or its agents.</p>
        <p>User agrees that any information or content that he or she posts, transmits or receives through caex-consulting.com will not be considered confidential. User grants caex-consulting.com</p>
        <p>an unlimited, irrevocable, royalty-free license to use, reproduce, display, copy, transmit, publicly perform, create derivative works, or communicate to the public any such information and content on a world-wide basis.</p>
        <p>User consents to caex-consulting.com collecting and processing any personal information, as per the terms of CAEX Canada Express Consulting's Privacy Policy and the information is accurate.</p>
        <p>Interactions between Users will be billed through CAEX Canada Express Consulting regardless of if the interaction is online or offline. User agrees to abide by the pricing terms agreed upon with an Sharer while utilizing caex-consulting.com and to pay CAEX Canada Express Consulting for all services rendered to User by Sharers.</p>
        <p>User will not take any actions that may undermine, disrupt or manipulate the integrity of the User feedback (rating) system on the Site.</p>
        <p>User will not interfere with or disrupt the caex-consulting.com servers or networks connected to the Site.</p>
        <p>User will not attempt to gain unauthorized access to other computer systems or networks connected to the Site.</p>
        <p>User will not transmit through the site any unlawful, harassing, libelous, privacy invading, abusive, threatening, defamatory, vulgar, obscene, racist, harmful, or otherwise objectionable material of any kind.</p>
        <p>User will not disobey or breach this Agreement or any other applicable instructions conveyed by CAEX Canada Express Consulting.</p>
        <p>User will not violate any applicable local, state, national or international law, statute, ordinance, rule, regulation or ethical code.</p>
        <p>User will not upload, post, e-mail, transmit or otherwise make available: (A) any information or material that infringes upon a third party right, especially intellectual property rights; (B) any third party advertisements, including banner exchange services; (C) any software viruses, Trojan horses, worms or any other malicious application; or (D) any information or material which may constitute or encourage conduct that is a criminal offense, civil wrong or which otherwise violates any applicable law.</p>
        <p>CAEX Canada Express Consulting enables the transfer of files between Users and Sharers. Opening any such files is at the sole risk of the User. It is the User's responsibility to scan such files with an anti-virus software application prior to opening them. CAEX Canada Express Consulting is not responsible for any the transfer of any computer viruses, malicious software or other damage resulting from opening such files.</p>
        <p>User will not stalk, threaten or harass Sharers or other Users or infringe upon or attempt to infringe upon their privacy.</p>
        <h2>International Users</h2>
        <p>CAEX Canada Express Consulting makes no claim that the content of the Site is appropriate or may be downloaded outside the United States. If you access the Site from a location outside the United States, you do so at your own risk and are responsible for compliance with the laws of your jurisdiction regarding online conduct and acceptable content.</p>
      </div>
      <Footer/>
      </React.Fragment>
  )
}

export default Policy