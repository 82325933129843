import React, {useState, useEffect} from 'react'
import Header from '../../components/header'
import { Helmet } from 'react-helmet';
import { useLocaleContext } from '../../utils/context'
import { submitDS160Form } from  '../../api/user' 
import { Modal, Tag, Form, Input, Button, message, Select } from 'antd';
import { CheckCircleOutlined, FilePdfOutlined, UserOutlined, WechatOutlined, MailOutlined} from '@ant-design/icons';
import img_line from '../../asset/img/docs_line.png'
import './index.less'
import { Text } from '../../utils/locale'

export default function Index() {

  const [ count, SetCount] = useState(3)
  const [ page, SetPage] = useState(1)
  const { locale } = useLocaleContext()

  const hiddeTidoLogo = (iframeContent) => {
      console.log('点击了')
      setTimeout(()=> {
          var element = document.querySelector('iframe[title="Tidio Chat"]');
          var iframeContent = element.contentDocument || element.contentWindow.document;
          var elementChild = iframeContent.querySelector('a[aria-label="Powered by Tidio."]');
          console.log(elementChild)
          // const body = iframeContent.querySelector('body');
          if (elementChild) {
              elementChild.parentNode.style.display = 'none'
          }
      },500)
  }

  useEffect(() => {
      setTimeout(() => {
          if (count > 0) {
              var element = document.querySelector('iframe[title="Tidio Chat"]');
              // console.log(element)
              if (element === null || element === undefined) {
                  SetCount(count-1)
              } else {
                  console.log(element)
                  var iframeContent = element.contentDocument || element.contentWindow.document;
                  console.log(iframeContent)
                  // element.addEventListener('click',hiddeTidoLogo)
                  const body = iframeContent.querySelector('body');
                  body.addEventListener('click',hiddeTidoLogo)
              }
              
          }
      }, 2000);
  },[count])

  const handlePrev = () => {
    SetPage(page-1)
    return
  }

  const handleSubmitConsult = () => {
      if (page !== 3) {
        SetPage(page+1)
        return
      }
      else {
        form.validateFields().then(values => {
            // form.resetFields()
            submitDS160Form({
                ...values
                // passport: values.passport, 
                // passport_city: values.passport_city, 
                // passport_issue: values.passport_issue, 
                // passport_expire: values.passport_expire,
                // fullname: values.fullname,
                // nativename: values.nativename,
                // formername: values.formername,
                // passportID: values.passportID,
                // gender: values.gender,
                // birthdate: values.birthdate,
                // birthcountry: values.birthcountry,
                // birthstate: values.birthstate,
                // birthcity: values.birthcity,
                // idnumber: values.idnumber,
                // ssn: values.ssn,
                // tcn: values.tcn,
                // address: values.address,
                // pr: values.pr,
                // worktel: values.worktel,
                // emaillist: values.emaillist,
                // tel: values.tel,
                // forigenlan: values.forigenlan,
                // arrested: values.arrested,
                // fathername: values.fathername,
                // fatherbirth: values.fatherbirth,
                // fatherinUS: values.fatherinUS,
                // mothername: values.mothername,
                // mothebirth: values.mothebirth,
                // motherinUS: values.motherinUS,
                // marrystatus: values.marrystatus,
                // spousename: values.spousename,
                // spousebirth: values.spousebirth,
                // spousenational: values.spousenational,
                // marrydate: values.marrydate,
                // divorcedate: values.divorcedate,
                // currworkplacename: values.currworkplacename,
                // currworkplacenameEN: values.currworkplacenameEN,
                // currworkplaceaddress: values.currworkplaceaddress,
                // currworkplacetel: values.currworkplacetel,
                // currworkplaceadvisorname: values.currworkplaceadvisorname,
                // currworkplaceadvisortel: values.currworkplaceadvisortel,
                // currworkposition: values.currworkposition,
                // currworkpstartdate: values.currworkpstartdate,
                // montlyincome: values.montlyincome,
                // socialmedia: values.socialmedia,
                // trippurpose: values.trippurpose,
                // tripcity: values.tripcity,
                // tripfirsnightaddress: values.tripfirsnightaddress,
                // tripfirstnighttel: values.tripfirstnighttel,
                // tripfellow: values.tripfellow,
                // tripfellowrelation: values.tripfellowrelation,
            }).then(res => {
            if (res.status === 200) {
                message.success(res.message)
                form.resetFields()
            } else {
                message.warning(res.message)
            }
            })
        }).catch(err => {
            message.warning('信息未填完整，请仔细检查！',6)
            return
        })
      }
  }
  const { Option } = Select;
  const [form] = Form.useForm()
  return (
      <React.Fragment>
          <Helmet>
              <title>DS-160填写审核</title>
              <meta
              name="description"
              content="美签申请表急速提交审核"
              />
              <meta
              name="keywords"
              content="美签咨询，美签预约，美签免面签申请，美签申请，美签代办，美签加急"
              />
          </Helmet>
          <Header/>
          <div className="ds160_container">
              <div className='ds160_form'>
                  <h2 className="con_title">美签申请 - DS-160（中文版）</h2>
                  <img className="img_line" src={img_line} alt=""></img>
                  <p className="con_tip">{Text.dsform106[locale]}</p>
                  <p className="con_tip">{Text.dsform107[locale]}</p>
                  <p className="con_tip">{Text.dsform108[locale]}</p>
                  <div className="container">
                          <>
                          {/* <Icon type="caret-right" />  */}
                          {/* <div className="methodDes">请任选一种方式联系我们，发送咨询</div> */}
                          <div className="wrap_consult">
                              <div className="left">
                              {/* <IconFont type="icon-form"className={styles.form} /> */}
                                  {/* <div className="methodTitle"> <strong>方式一</strong></div>
                                  <p className="formAlert">请告诉我们你的基本信息，我们将匹配客服专员联系你</p> */}
                                  <Form 
                                      layout="vertical"
                                      form={form}
                                      className="form_content"
                                      size="middle"
                                  >
                                    { page === 1 &&
                                    <>
                                      <Form.Item
                                          label={Text.dsform1[locale]}
                                          name="passport"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item>
                                      <Form.Item
                                          label={Text.dsform2[locale]}
                                          name="passport_city"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item>
                                      <Form.Item
                                          label={Text.dsform3[locale]}
                                          name="passport_country"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item>
                                      <Form.Item
                                          label={Text.dsform4[locale]}
                                          name="passport_issue"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform5[locale]}
                                          name="passport_expire"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item>  
                                      <Form.Item
                                          label={Text.dsform6[locale]}
                                          name="fullname"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item>  
                                      <Form.Item
                                          label={Text.dsform7[locale]}
                                          name="nativename"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item>  
                                      <Form.Item
                                          label={Text.dsform8[locale]}
                                          name="formername"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform9[locale]}
                                          name="passportID"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform10[locale]}
                                          name="gender"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform11[locale]}
                                          name="birthdate"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform12[locale]}
                                          name="birthcountry"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform13[locale]}
                                          name="birthstate"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform13b[locale]}
                                          name="birthcity"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform14[locale]}
                                          name="idnumber"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform15[locale]}
                                          name="ssn"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform16[locale]}
                                          name="tcn"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform17[locale]}
                                          name="address"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform18[locale]}
                                          name="pr"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform19[locale]}
                                          name="worktel"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform20[locale]}
                                          name="emaillist"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform21[locale]}
                                          name="tel"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform22[locale]}
                                          name="forigenlan"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform23[locale]}
                                          name="arrested"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item>
                                    </>
                                    }
                                    { page === 2 &&
                                        <>
                                        <Form.Item
                                            label={Text.dsform24[locale]}
                                            name="fathername"
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: Text.dsform0[locale],
                                                },
                                            ]}
                                        >
                                            <Input placeholder={Text.dsform0[locale]}/>
                                        </Form.Item> 
                                         
                                      <Form.Item
                                          label={Text.dsform25[locale]}
                                          name="fatherbirth"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform26[locale]}
                                          name="fatherinUS"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform27[locale]}
                                          name="mothername"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform28[locale]}
                                          name="mothebirth"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform30[locale]}
                                          name="motherinUS"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform31[locale]}
                                          name="marrystatus"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform32[locale]}
                                          name="spousename"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform33[locale]}
                                          name="spousebirth"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform34[locale]}
                                          name="spousenational"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform35[locale]}
                                          name="marrydate"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform36[locale]}
                                          name="divorcedate"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform37[locale]}
                                          name="currworkplacename"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform38[locale]}
                                          name="currworkplacenameEN"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform39[locale]}
                                          name="currworkplaceaddress"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform40[locale]}
                                          name="currworkplacetel"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform44[locale]}
                                          name="currworkplaceadvisorname"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform45[locale]}
                                          name="currworkplaceadvisortel"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform46[locale]}
                                          name="currworkposition"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform47[locale]}
                                          name="currworkpstartdate"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform48[locale]}
                                          name="montlyincome"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform49[locale]}
                                          name="socialmedia"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      </> 
                                    }
                                    { page === 3 &&
                                    <>
                                      <Form.Item
                                          label={Text.dsform50[locale]}
                                          name="trippurpose"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform51[locale]}
                                          name="tripdate"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform52[locale]}
                                          name="tripstayday"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform53[locale]}
                                          name="tripcity"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform54[locale]}
                                          name="tripfirsnightaddress"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform56[locale]}
                                          name="tripfirstnighttel"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform57[locale]}
                                          name="tripfellow"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform58[locale]}
                                          name="tripfellowrelation"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform59[locale]}
                                          name="emergencyname"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform60[locale]}
                                          name="emergencytel"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform61[locale]}
                                          name="emergencyemail"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 

                                      <Form.Item
                                          label={Text.dsform63[locale]}
                                          name="relativeinUS"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform67[locale]}
                                          name="beenUS"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform68[locale]}
                                          name="beenUSdetail"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform69[locale]}
                                          name="prevvisatype"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform70[locale]}
                                          name="prevvisaissusdate"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform71[locale]}
                                          name="prevvisanum"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform72[locale]}
                                          name="rejectnum"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform73[locale]}
                                          name="rejectdate"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform74[locale]}
                                          name="rejecttype"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform75[locale]}
                                          name="revoke"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform76[locale]}
                                          name="applyimmigration"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform77[locale]}
                                          name="ban"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform78[locale]}
                                          name="studypublic"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform79[locale]}
                                          name="countryfiveyearvisit"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform80[locale]}
                                          name="lostpassport"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform81[locale]}
                                          name="lostpassportdetail"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform82[locale]}
                                          name="workplacename1"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform83[locale]}
                                          name="workplaceaddress1"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform84[locale]}
                                          name="workplacetel1"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform85[locale]}
                                          name="workplaceposition1"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform86[locale]}
                                          name="workplaceadvisor1"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform87[locale]}
                                          name="workplaceduration1"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform88[locale]}
                                          name="workplacename2"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform89[locale]}
                                          name="workplaceaddress2"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform90[locale]}
                                          name="workplacetel2"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform91[locale]}
                                          name="workplaceposition2"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform92[locale]}
                                          name="workplaceadvisor2"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform93[locale]}
                                          name="workplaceduration2"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform94[locale]}
                                          name="army"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform95[locale]}
                                          name="schoolname1"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform96[locale]}
                                          name="schooladdress1"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform97[locale]}
                                          name="schooltel1"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform98[locale]}
                                          name="schoolmajor1"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform99[locale]}
                                          name="schoolduration1"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform100[locale]}
                                          name="schoolname2"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform101[locale]}
                                          name="schooladdress2"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform102[locale]}
                                          name="schooltel2"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform103[locale]}
                                          name="schoolmajor2"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform104[locale]}
                                          name="schoolduration2"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                      <Form.Item
                                          label={Text.dsform105[locale]}
                                          name="extrainfo"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item> 
                                    </>
                                    }   
                                      {/* <Form.Item
                                          label={Text.dsform6[locale]}
                                          name="passport"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item>                       */}
                                      {/* <Form.Item
                                          name="type" 
                                          hasFeedback                                      
                                      >
                                          <Select placeholder="选择类型" size="large">
                                              <Option value="1">私人订制咨询30分钟</Option>
                                              <Option value="2">私人订制咨询无限时</Option>
                                              <Option value="3">美签申请</Option>
                                              <Option value="4">其他</Option>
                                          </Select>
                                      </Form.Item> */}
                                      <Form.Item>
                                            <div className='foot_btn_container'> 
                                                <Button type="info" size="large" onClick={handlePrev}>
                                                {(page === 2 || page === 3) && <span>上一步</span>}
                                                </Button>
                                                {/* <Button type="info" size="large" onClick={handleNext}>
                                                    {(page === 1 || page === 2) && <span>下一步</span>}
                                                </Button> */}
                                                <Button type="primary" size="large" onClick={handleSubmitConsult}>
                                                    {page === 3 && <span>提交</span>}
                                                    {(page === 1 || page === 2) && <span>下一步</span>}
                                                </Button>
                                           </div>
                                      </Form.Item> 
                                  </Form>
                              </div> 
                              {/* <div className="right">
                                  <div className="methodTitle"><strong>方式二</strong></div>
                                  <div className="con">
                                      <img src={rosalyn_wechat} alt="rosalyn_wechat" />
                                      <p>扫码免费预约时间
                                          <br />
                                          订制属于你的专属咨询方案
                                      </p>
                                  </div>
                              </div> */}
                          </div>
                          </> 
                      {/* { page === 2 &&
                      <div>
                              <Result status="success" title="提交成功，请添加Lexy老师微信咨询~" extra={
                                  <div className={styles.advisor_qrcode}>
                                      <img className={styles.img_wechat} src={img_wechat} alt="qrcode"></img>
                                      <p>有任何问题可以联系Lexy老师</p>
                                  </div>
                              }/>
                      </div> } */}
                  </div>
              </div>
          </div>
      </React.Fragment>
  )
}