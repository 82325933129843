import React, {useState, useEffect} from 'react'
import './index.less'
import { Link } from 'react-router-dom'
import leftimg from '../../asset/img/land1.jpg'
import ca from '../../asset/img/canada.png'
import usa from '../../asset/img/usa.png'
import logo from '../../asset/img/ca-exlogo1.png'
import { Storage } from '../../utils/tools'
import { Text } from '../../utils/locale'
import { useLocaleContext } from '../../utils/context'
import { TypeAnimation } from 'react-type-animation'
import { Helmet } from 'react-helmet';

export default function Index() {
    const { locale } = useLocaleContext()

    const handleSetCounrty = (country) => {
        Storage.setLocal('country',country)
    }

    // useEffect(() => {
    //     const country = Storage.getLocal('country')
    //     console.log(country)
    //     if (country !== false && country !== null) {
    //         if (country === 'us') {
    //             window.location.replace(window.location.origin+"/")    
    //         } 
    //         if (country === 'ca') {
    //             window.location.replace(window.location.origin+"/canada")    
    //         }
    //     }
    // },[])


    return (
        <React.Fragment>
        <Helmet>
            <title>开始我的签证申请</title>
            <meta
                name="description"
                content="北美经验丰富的移民顾问团队指导您完成从初步咨询到签证批准的每一步。"
            />
            <meta
                name="keywords"
                content="美国签证办理,加拿大签证办理"
            />
        </Helmet>
        <div className="landing_wrapper">
            <div className="landing_left">   
            <div className='slogan'>
                <div className='slogantop'></div>
                <TypeAnimation
                    sequence={[
                        // Same substring at the start will only be typed once, initially
                        Text.landingPage.slogan1[locale],
                        1500,
                        Text.landingPage.slogan2[locale],
                        2000,
                        Text.landingPage.slogan3[locale],
                        1000,
                        Text.landingPage.slogan4[locale],
                        1000,
                        Text.landingPage.slogan5[locale],
                        1000,
                    ]}
                    wrapper="span"
                    cursor={true}
                    repeat={Infinity}
                    style={{ fontSize: '40px', fontWeight: 600, display: 'inline-block' }}
                />
            </div>
            {/* <img className="leftimg" src={leftimg} alt='leftimg'></img> */}
            </div>
            <div className="landing_right">
                <div className='right_wrapper'>
                    <img src={logo} className="logo" alt="" />
                    <div className='heading'>{Text.landingPage.heading[locale]}</div>
                    <h1>{Text.landingPage.heading2[locale]}</h1>
                    <div className='bottom'>
                        <a className='usa' href="/" onClick={()=>handleSetCounrty('us')}>
                            <img src={usa} alt="" />
                            <span>{Text.landingPage.us[locale]}</span>
                        </a>
                        <a className='canada'  href="/canada" onClick={()=>handleSetCounrty('ca')}>
                            <img src={ca} alt="" />
                            <span>{Text.landingPage.ca[locale]}</span>
                        </a>
                    </div>
                    <div className='horizontal-rule'></div>
                    <h3>{Text.landingPage.desc[locale]}</h3>
                    <span>{Text.landingPage.desc2[locale]}</span>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}