// import img_wechat from '@/assets/img/chloe_wechat.jpeg'
import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import './pay_qrcode.less';
import imgWechat from '../../asset/img/pay_wechat.png'



function PayQrcode({ refVisible, changeVisible }, ref) {

    const [visible, setVisible] = useState(false)

	useEffect(() => {
        setVisible(refVisible)
    },[refVisible])

    const onClose = () => {
        setVisible(false)
        changeVisible(false)
	}

	return (

        <Modal className="payQrcodeCon"
			width={400}
			visible={visible}
			onCancel={onClose}
            centered
            // prefixCls= 'main-ant-modal'
			// afterClose={afterClose}
			footer={null}>
            <div className="container">
                <div className="right">
                    <div className="methodTitle"></div>
                    <div className="con">
                        <img src={imgWechat} alt="wechat"></img>
                        <p>添加顾问协助您支付费用<br />请耐心等待验证通过！</p>
                    </div>
                </div>
            </div>
		</Modal>
	);
}

export default (PayQrcode)