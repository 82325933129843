import React from 'react';
import './index.less'
import Header from '../../components/header'
import Footer from '../../components/footer'

function Policy () {
  return (
      <React.Fragment>
      <Header blank={true}/>
      <div className='policyContainer'>
        <h2>User Policy</h2>
        <p>This User Policy ("Policy") constitutes the legally binding agreement between CAEX Canada Express Consulting Co. ("Company") and the user ("User") regarding the purchase and use of the visa appointment slot notification service provided by the Company. By accessing, subscribing to, or using the Service, you acknowledge that you have read, understood, and agreed to comply with the terms and conditions outlined herein.</p>

        <h3>1. Refund Policy:</h3>
        <p>We deeply regret to inform you that the Company does not offer any refund services for the subscription fees paid for the Service. Upon subscribing to any of our plans, the associated fees become non-refundable under any circumstances. Prior to initiating a subscription, we earnestly urge you to engage in a comprehensive assessment of your individual visa requirements, preferences, and plans. This will facilitate a well-informed decision-making process.</p>

        <h3>2. Appointment Slot Availability:</h3>
        <p>While the Company's Service is designed to provide timely and pertinent information about available visa appointment slots, it is essential to recognize that the mere notification does not guarantee the successful booking of an appointment. The allocation of visa appointment slots is subject to a multitude of factors, encompassing but not limited to the number of applicants, consulate policies, and unforeseeable changes in the appointment release process. The Company's primary intent is to enhance the convenience of users by facilitating access to slot availability information.</p>

        <h3>3. Subscription Types and Limitations:</h3>
        <p>At present, the Company does not offer the capability to concurrently subscribe to different types of plans. Users are required to select a single plan that aligns with their specific needs and preferences. This approach is instituted to streamline and enhance the user experience, ensuring that the Company's resources are optimally allocated to provide exceptional services within the subscribed plan.</p>

        <h3>4. Notification Frequency and Patterns:</h3>
        <p>The Company commits to employing diligent efforts in the continuous monitoring of visa appointment slot availability at respective consulates. However, the frequency and timing of notifications are intrinsically contingent upon the policies and practices of the consulates themselves. It is imperative to recognize that appointment slots are not released at uniform intervals and are influenced by dynamic variables. Thus, the frequency of notifications may vary over time.</p>

        <h3>5. Transparent Pricing:</h3>
        <p>It is a paramount principle for the Company to operate with the utmost transparency and integrity in its interactions with users. As such, the subscription fees associated with each plan are explicitly communicated and do not harbor any hidden fees or supplementary charges. The fee you pay at the time of subscription corresponds directly to the plan you have selected and the services rendered under that specific plan. Rest assured, there are no unforeseen or undisclosed charges.</p>

        <p>Before embarking on your subscription journey, it is imperative that you meticulously peruse, understand, and internalize the aforementioned clauses and stipulations. This endeavor will empower you to make an educated and well-considered decision, ensuring that you can harness the full potential of the Company's visa appointment slot monitoring service. Should you possess any inquiries or require further clarification, we wholeheartedly encourage you to engage with us through the designated channels.</p>

        <p>By subscribing to the Company's Service, you unequivocally acknowledge your acceptance of and compliance with these terms. The Company reserves the prerogative to revise, amend, or update this User Policy without prior notice. In the event of any modifications, the Company undertakes to communicate these changes to subscribers through appropriate channels. Your continued utilization of the Service following any such revisions constitutes your binding consent to the modified terms.</p>
      </div>
    <Footer/>
    </React.Fragment>
  )
}

export default Policy