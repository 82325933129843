import React, {useState, useEffect} from 'react'
import { Tools, Storage } from '../../utils/tools'
import { Menu, Button, Rate, Tag, Modal, Descriptions, Badge, } from 'antd';
import { Link } from 'react-router-dom'
import './index.less'
import { getSession } from '../../api/user'
import { CheckCircleFilled, TagsOutlined, AlertOutlined, UserOutlined, LogoutOutlined, ClusterOutlined, ScheduleFilled, EnvironmentOutlined, RocketOutlined,UsergroupAddOutlined,CheckCircleOutlined, MoneyCollectOutlined  } from '@ant-design/icons';
import Header from '../../components/header'
import Footer from '../../components/footer'
// WebWorker helper class
import WebWorker from '../../utils/webWorker';
// Your web worker
import LoopWorker from '../../worker/loopWorker';

import company_logo from '../../asset/img/company_logo.png'
import contact_agent from '../../asset/img/contact_agent.jpg'
import welcome_right from '../../asset/img/welcome-right.png'
import assistant1 from '../../asset/img/assistant1.png'
import aboutonlinevisa from '../../asset/img/about-onlinevisa.jpg'
import gd from '../../asset/img/grachic-designer.jpg'
import { useLocaleContext } from '../../utils/context'
import { Text } from '../../utils/locale'
import ds150 from '../../asset/img/ds150.jpg'
import stu150 from '../../asset/img/stu150.jpg'
import en150 from '../../asset/img/en150.jpg'
import dev150 from '../../asset/img/dev150.jpg'
import sa150 from '../../asset/img/sa150.jpeg'
import sm150 from '../../asset/img/sm150.jpg'
import top1 from '../../asset/img/top1.jpg'
import top2 from '../../asset/img/top2.jpg'
import top3 from '../../asset/img/top3.jpg'
import PlanConsultQrcode from '../../components/plan/consult_qrcode'
import { Helmet } from 'react-helmet';

// console.log('Init worker...')

// // Worker init
// const workerInstance = new WebWorker(LoopWorker) 


// // Listening for messages from worker
// workerInstance.addEventListener("message", e => {

//   console.log('[MAIN] MSG FROM WORKER: ', e.data)


// }, false)


// workerInstance.postMessage("Hi, worker! I'm main thread!")
export default function Index() {
    const [ userInfo, SetUserInfo ] = useState(undefined)
    const [ mobile, SetMobile ] = useState(false)
    const [ consularVisible, SetConsularVisible ] = useState(false)
    const [ consularModalData, SetConsularModalData] = useState({})
    const [ count, SetCount] = useState(3)
    const { locale } = useLocaleContext()
    const [refVisibleConsult, setRefVisibleConsult] = useState(false)
    
    const token = Tools.getToken()
    console.log('Init worker...')
    const workerInstance = new WebWorker(LoopWorker);
    
    useEffect(() => {
        const country = Storage.getLocal('country')
        // console.log(country)
        if (country === false || country === null) {
            window.location.replace(window.location.origin+"/country/select")    
        }
        isMobile()
        workerInstance.postMessage("bar");  
        workerInstance.onmessage = function(evt) {
            // console.log('主收'+evt.data)
            console.log(evt)
        }
    }, [])
    

    const hiddeTidoLogo = (iframeContent) => {
        console.log('点击了')
        setTimeout(()=> {
            var element = document.querySelector('iframe[title="Tidio Chat"]');
            var iframeContent = element.contentDocument || element.contentWindow.document;
            var elementChild = iframeContent.querySelector('a[aria-label="Powered by Tidio."]');
            console.log(elementChild)
            // const body = iframeContent.querySelector('body');
            if (elementChild) {
                elementChild.parentNode.style.display = 'none'
            }
        },500)
    }

    useEffect(() => {
        setTimeout(() => {
            if (count > 0) {
                var element = document.querySelector('iframe[title="Tidio Chat"]');
                // console.log(element)
                if (element === null || element === undefined) {
                    SetCount(count-1)
                } else {
                    console.log(element)
                    var iframeContent = element.contentDocument || element.contentWindow.document;
                    console.log(iframeContent)
                    // element.addEventListener('click',hiddeTidoLogo)
                    const body = iframeContent.querySelector('body');
                    body.addEventListener('click',hiddeTidoLogo)
                }
                
            }
        }, 2000);
    },[count])

    useEffect(() => {
        if(token !== '') {
            getSession({token}).then(res => {
                if (res.status_code === 200) {
                    SetUserInfo(res.data)
                }
            })
        }else{
            SetUserInfo(null)
        }
    }, [token])

    const handleLogout = () => {
        //清除token
        console.log('退出了')
        Tools.userLogout()
        SetUserInfo(null)
    }

    const isMobile = () => {
        SetMobile(/(iPhone|iPod|Android|SymbianOS|Windows Phone)/i.test(navigator.userAgent))
    }

    const menu = (
        <Menu className="menu">
          <Menu.Item  key="0" icon={<UserOutlined />} >
            <Link to="/user/profile">我的账户</Link>
          </Menu.Item>
          {/* <Menu.Item key="1">
            <a href="http://www.taobao.com/">2nd menu item</a>
          </Menu.Item> */}
          <Menu.Divider />
          <Menu.Item  key="3" icon={<LogoutOutlined />}>
              <span onClick={() =>handleLogout()}>退出登录</span>
          </Menu.Item>
        </Menu>
    );
    

    const columns_mobile = [
        {
            title: "City",
            dataIndex: "name",
            key: "name",
            width: "15%",
            render: (text) => <b>{text}</b>,
        },
        {
            title: "Earliest Date",
            dataIndex: "time",
            width: "30%",
            key: "etime",
            render: (text) => <a> {text} </a>,
        },
    ]

    const columns = [
        {
          title: "City",
          dataIndex: "name",
          key: "name",
          width: "5%",
          render: (text) => <b>{text}</b>,
        },
        {
          title: "Available Visa Type",
          dataIndex: "age",
          key: "age",
          width: "15%"
        },
        {
          title: "Address",
          dataIndex: "address",
          width: "18%",
          key: "address"
        },
        {
          title: "Earliest Date",
          dataIndex: "time",
          width: "8%",
          key: "etime",
          render: (text) => <a> {text} </a>,
        },
        {
          title: "Update Date",
          dataIndex: "utime",
          width: "12%",
          key: "utime"
        },
        {
          title: "Estimate Next Release",
          dataIndex: "etime",
          width: "12%",
          key: "etime",
          render: (tags) => (
            <Tag>
                  {tags}
            </Tag>
          )
        }
    ];

    const consulateData = [
        {
            name: '温哥华美国领事馆',
            location: "1075 W Pender St, Vancouver, BC V6E 2M6",
            emergency_contact: "604-685-4311",
            mail_location: "112 - 1231 Pacific Blvd Vancouver, BC V6Z 0E2",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "受理",
            status: "正常",
            status_prop: "success"
        },
        {
            name: '多伦多美国领事馆',
            location: "360 University Ave, Toronto, ON M5G 1S4",
            emergency_contact: "416-201-4056",
            mail_location: "360 University Ave, Toronto, ON M5G 1S4",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "受理",
            status: "正常",
            status_prop: "success"
        },
        {
            name: '卡尔加里美国领事馆',
            location: "615 MacLeod Trail SE Rocky Mountain Plaza Building Calgary, Alberta T2G 4T84",
            emergency_contact: "403-266-8962",
            mail_location: "615 MacLeod Trail SE Rocky Mountain Plaza Building Calgary, Alberta T2G 4T84",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "受理",
            status: "正常",
            status_prop: "success"
        },
        {
            name: '渥太华美国领事馆',
            location: "490 Sussex Dr, Ottawa, ON K1N 1G8",
            emergency_contact: "613-688-5335",
            mail_location: "490 Sussex Dr, Ottawa, ON K1N 1G8",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "受理",
            status: "正常",
            status_prop: "success"
        },
        {
            name: '魁北克美国领事馆',
            location: "2, rue de la Terrasse-Dufferin Québec, Québec G1R 4T9",
            emergency_contact: "418-692-2095",
            mail_location: "Succursale Haute-Ville, C.P. 939 Québec, Québec G1R 4T9",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "不受理",
            status: "停滞",
            status_prop: "warning"
        },
        {
            name: '哈利法克思美国领事馆',
            location: "1973 Upper Water Street Halifax, NS B3J 0A9",
            emergency_contact: "4902-429-2480",
            mail_location: "1973 Upper Water Street Halifax, NS B3J 0A9",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "不受理",
            status: "停滞",
            status_prop: "warning"
        },
        {
            name: '蒙特利尔美国领事馆',
            location: "1134 Sainte-Catherine Street West Montréal, Québec H3B 1H4",
            emergency_contact: "844-277-8805",
            mail_location: "955, 1re Avenue #USA Sainte-Catherine, Québec J5C 1C5",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "不受理",
            status: "停滞",
            status_prop: "warning"
        },
    ]

    const showModal = (index) => {
        SetConsularModalData(consulateData[index])
        SetConsularVisible(true);
    };
    
    const handleOk = () => {
        SetConsularVisible(false);
    };
    const handleCancel = () => {
        SetConsularVisible(false);
    }

    const openConsult = e => {
        setRefVisibleConsult(true)
    }

    const changeVisibleConsult = e => {
        setRefVisibleConsult(e)
    }

    return (
        <React.Fragment>
        <Helmet>
            <title>加拿大签证</title>
            <meta
                name="description"
                content="为您提供获得签证的最佳途径,最节省时间和工序"
            />
            <meta
                name="keywords"
                content="学生签证,工作签证,旅游签证,探亲签证"
            />
        </Helmet>
        <div className="homePage_wrapper_ca">
            <PlanConsultQrcode refVisible={refVisibleConsult} changeVisible={changeVisibleConsult}/>
            <Modal title={consularModalData["name"]} width={780} centered={true} visible={consularVisible} onCancel={handleCancel} footer={null} onOk={handleOk}>
                <Descriptions title="" layout="vertical" bordered>
                    <Descriptions.Item label="地点">{consularModalData["location"]}</Descriptions.Item>
                    <Descriptions.Item label="紧急电话">{consularModalData['emergency_contact']}</Descriptions.Item>
                    <Descriptions.Item label="邮寄地址">{consularModalData['mail_location']}</Descriptions.Item>
                    <Descriptions.Item label="假期时间"><a href='https://ca.usembassy.gov/holiday-calendar/'>https://ca.usembassy.gov/holiday-calendar/</a></Descriptions.Item>
                    <Descriptions.Item label="加急申请">
                    {consularModalData['expedict']}
                    </Descriptions.Item>
                    <Descriptions.Item label="当前状态">
                    <Badge status={consularModalData['status_prop']} text={consularModalData['status']}  />
                    </Descriptions.Item>
                </Descriptions>
            </Modal>
            <Header index={false} site={'CANADA'}/>
                <section className='sec-welcome'>
                    {/* <img alt="front-page" src={vector} className='front-page-img-vector'></img>
                    <img alt="front-page" src={vector2} className='front-page-img-vector2'></img> */}
                    <div className='welcome-container'>
                        <div className='left'>
                            <div className='topleft-container'>
                                <div className='one'>
                                    <div>
                                        <Tag className='tag' color="volcano">{Text.canadaSite.welcome0[locale]}</Tag>
                                    </div>
                                </div>
                                <div className='two'>
                                    <div className='des'>
                                    {Text.canadaSite.welcome1[locale]}	
                                    </div>
                                </div>
                                <div className='three'>

                                </div>
                                <div className='four'>
                                    {/* <div className='text'>{Text.canadaSite.welcome2[locale]}</div> */}
                                    <div className='text'>{Text.canadaSite.welcome2[locale]}</div>
                                </div>
                                <div className='five'>
                                    <img src={top1} alt="" />
                                    <img src={top2} alt="" />
                                    <img src={top3} alt="" />
                                    <div className='text'>{Text.canadaSite.welcome3[locale]}</div>
                                </div>
                                <div className='six'>
                                    <Button
                                    // onClick={e => openConsult(e)}
                                    // onClick={handleClick}
                                        className={locale === 'en' ? "btn_explore" : "btn_explore_cn"}
                                    ><a href="#targetElement1">{Text.canadaSite.welcome4[locale]}</a></Button>
                                </div>

                            </div>
                        </div>
                        <div className="right">
                            <div className='topright-container'>
                                {/* <div className='flight_container'>
                                    <img src="https://templatekit.jegtheme.com/vizago/wp-content/uploads/sites/401/2023/08/graphic-plane.png" alt="flight" />
                                </div> */}
                                <div className='ing_container'>
                                    {/* <img src="https://templatekit.jegtheme.com/vizago/wp-content/uploads/sites/401/2023/08/image-hero.png" alt="bg" /> */}
                                    <img src={welcome_right} alt="bg" />
                                </div>
                                <div className='bottom_container'>
                                </div>
                            </div>
                        </div>

                        {/* { mobile === false && <img alt="front-page" src={vector} className='front-page-img-vector' />} */}
                        {/* { mobile === true && <img alt="front-page" src={frontpage_mb} className='front-page-img-mb' />} */}
                     
                        {/* <div className='sentence-container'>
                            <span className="welcome-s1">
                                “美签面试有着落了吗?”
                            </span>
                            <span className="welcome-s2">
                                “问<span className='welcome-brand'>Canada Express</span>吧！” 
                            </span>
                        </div>
                        <img src={welcomeWomen} alt="welcome-women" className="welcome-women"></img>
                        <img src={welcomeMan}  alt="welcome-man" className="welcome-man"></img> */}
                    </div>
                </section>
                <section className='sec-trust'  id="targetElement1">
                    <div className='trust-container'> 
                        <div className='left'>
                            <div className='one'>
                                {Text.canadaSite.trust0[locale]} <br/> {Text.canadaSite.trust1[locale]}
                            </div>
                            <div className='two'>
                                {Text.canadaSite.trust2[locale]}
                            </div>
                        </div>
                        <div className='right'>
                            <div>
                                <img src={company_logo} alt="company_logo" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='sec-about'>
                    <div className='intro-container'>
                        <div className='left'>
                            <div className='topright-container'>
                                <div className='img_container'>
                                    {/* <img src="https://templatekit.jegtheme.com/vizago/wp-content/uploads/sites/401/2023/08/secretary-PMMQK8T-842x1024.png" alt="bg" /> */}
                                    <img src={assistant1} alt="bg" />
                                </div>
                                <div className='bottom_container'>
                                </div>
                            </div>
                        </div>
                        <div className='right'>
                            <div>
                                <Tag className='tag' color="volcano"> {Text.canadaSite.about0[locale]}</Tag>
                            </div>
                            <div className='title'> 
                                {Text.canadaSite.about1[locale]}
                            </div>
                            <div className='desc'>
                                {Text.canadaSite.about2[locale]}
                            </div>
                            <div className='mid-container'>
                                <div className="left">
                                    <div className='title'>
                                        <CheckCircleFilled className='icon' />
                                        <div className='text'>
                                        {Text.canadaSite.about3[locale]}
                                        </div>
                                    </div>
                                    <div className='title-desc'>
                                    {Text.canadaSite.about4[locale]} 
                                    </div>
                                    <div className='title'>
                                        <CheckCircleFilled className='icon' />
                                        <div className='text'>
                                        {Text.canadaSite.about5[locale]}
                                        </div>
                                    </div>
                                    <div className='title-desc'>
                                        {Text.canadaSite.about6[locale]}
                                    </div>
                                </div>
                                <div className="right">
                                    <img className='img' src={aboutonlinevisa} alt="aboutonlinevisa" />
                                </div>
                            </div>
                            <div className="divider" />

                            <div className="btm-container">
                                <div className="left">
                                    <Button
                                    // onClick={e => openConsult(e)}
                                    // onClick={handleClick}
                                        className={locale === 'en' ? "btn_explore" : "btn_explore_cn"}
                                    ><a href="#targetElement2">{Text.canadaSite.about7[locale]}</a></Button>
                                </div>
                                <div className="right">
                                    <div className="contact">
                                        <img src={contact_agent} alt="" />
                                        <div className="text">
                                            <p>{Text.canadaSite.about8[locale]}</p>
                                            <p>(+1) 778-872-2881</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                <section className='sec-category' id="targetElement2">
                    <div className="category-container">
                        <Tag className='tag' color="volcano">{Text.canadaSite.cate0[locale]}</Tag>
                        <div className="title">
                            {Text.canadaSite.cate1[locale]}
                        </div>
                        <div className="list">
                            <div className="card student">
                                <div className="desc">
                                    <h3>{Text.canadaSite.cate2[locale]}</h3>
                                    <p>{Text.canadaSite.cate3[locale]}</p>
                                </div>
                                <div className="overlay"></div>
                            </div>
                            <div className="card work">
                                <div className="overlay"></div>
                                <div className="desc">
                                    <h3>{Text.canadaSite.cate4[locale]}</h3>
                                    <p>{Text.canadaSite.cate5[locale]}</p>
                                </div>
                            </div>
                            <div className="card tourist">
                                <div className="overlay"></div>
                                <div className="desc">
                                    <h3>{Text.canadaSite.cate6[locale]}</h3>
                                    <p>{Text.canadaSite.cate7[locale]}</p>
                                </div>
                            </div>
                            <div className="card buiness">
                                <div className="overlay"></div>
                                <div className="desc">
                                    <h3>{Text.canadaSite.cate8[locale]}</h3>
                                    <p>{Text.canadaSite.cate9[locale]}</p>
                                </div>
                            </div>
                            <div className="card family">
                                <div className="overlay"></div>
                                <div className="desc">
                                    <h3>{Text.canadaSite.cate10[locale]}</h3>
                                    <p>{Text.canadaSite.cate11[locale]}</p>
                                </div>
                            </div>
                            <div className="card super">
                                <div className="overlay"></div>
                                <div className="desc">
                                    <h3>{Text.canadaSite.cate12[locale]}</h3>
                                    <p>{Text.canadaSite.cate13[locale]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='sec-success'>
                    <div className="overlay"></div>
                    <div className="success-container">
                        <div className="top">
                            <div className="content">
                                <span className='prefix'>10K </span> <span className='suffix'>+</span>
                                <div className='desc'>{Text.canadaSite.success0[locale]}</div>
                            </div>
                            <div className="content">
                                <span className='prefix'>10 </span> <span className='suffix'>+</span>
                                <div className='desc'>{Text.canadaSite.success1[locale]}</div>
                            </div>
                            <div className="content">
                                <span className='prefix'>15K </span> <span className='suffix'>+</span>
                                <div className='desc'>{Text.canadaSite.success2[locale]}</div>
                            </div>
                            <div className="content">
                                <span className='prefix'>10 </span> <span className='suffix'>+</span>
                                <div className='desc'>{Text.canadaSite.success3[locale]}</div>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="left">
                            </div>
                            <div className="right">
                                <div className='title'>
                                    {Text.canadaSite.success4[locale]}
                                </div>
                                <div className='desc'>
                                    {Text.canadaSite.success5[locale]}
                                </div>
                                <Button
                                // onClick={e => openConsult(e)}
                                // onClick={handleClick}
                                onClick={e => openConsult(e)}
                                className={locale === 'en' ? "btn_explore" : "btn_explore_cn"}
                                >{Text.canadaSite.success6[locale]}</Button>
                            
                            </div>
                        </div>
                    </div>
                </section>
                <section className='sec-review'>
                    <div className="review-container">
                        <Tag className='tag' color="volcano">{Text.canadaSite.review00[locale]}</Tag>
                        <div className="title">
                        {Text.canadaSite.review0[locale]}
                        </div>
                        {/* <div className="desc">
                        {Text.canadaSite.review1[locale]}
                        </div> */}
                        <div className="list">
                            <div className="card">
                                <div className="top">
                                    {Text.canadaSite.review2[locale]}
                                </div>
                                <div className="star">
                                    <Rate className="rate" defaultValue={5} disabled={true}/>
                                </div>
                                <div className="bottom">
                                    <img src={dev150} alt="" />
                                    <div className="text">
                                        <p>John Carter</p>
                                        <p> {Text.canadaSite.review3[locale]} </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card ">
                                <div className="top">
                                {Text.canadaSite.review4[locale]}
                                </div>
                                <div className="star">
                                    <Rate className="rate" defaultValue={5} disabled={true}/>
                                </div>
                                <div className="bottom">
                                    <img src={stu150} alt="" />
                                    <div className="text">
                                        <p>XueLiu Lin</p>
                                        <p> {Text.canadaSite.review5[locale]} </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card ">
                                <div className="top">
                                {Text.canadaSite.review6[locale]}
                                </div>
                                <div className="star">
                                    <Rate className="rate" defaultValue={5} disabled={true}/>
                                </div>
                                <div className="bottom">
                                    <img src={en150} alt="" />
                                    <div className="text">
                                        <p>Jacob Thompson</p>
                                        <p> {Text.canadaSite.review7[locale]} </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card ">
                                <div className="top">
                                {Text.canadaSite.review8[locale]}                                
                                </div>
                                <div className="star">
                                    <Rate className="rate" defaultValue={5} disabled={true}/>
                                </div>
                                <div className="bottom">
                                    <img src={ds150} alt="gd" />
                                    <div className="text">
                                        <p>Ahmed Al-Farsi</p>
                                        <p> {Text.canadaSite.review9[locale]} </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="top">
                                {Text.canadaSite.review10[locale]}
                                </div>
                                <div className="star">
                                    <Rate className="rate" defaultValue={5} disabled={true}/>
                                </div>
                                <div className="bottom">
                                    <img src={sa150} alt="" />
                                    <div className="text">
                                        <p>Cindy Lee</p>
                                        <p> {Text.canadaSite.review11[locale]} </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card ">
                                <div className="top">
                                {Text.canadaSite.review12[locale]}
                                </div>
                                <div className="star">
                                    <Rate className="rate" defaultValue={5} disabled={true}/>
                                </div>
                                <div className="bottom">
                                    <img src={sm150} alt="" />
                                    <div className="text">
                                        <p>Ben Simmon</p>
                                        <p> {Text.canadaSite.review13[locale]} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer site={"CANADA"}/>
        </div>
        </React.Fragment>
   )
}