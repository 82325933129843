// import img_wechat from '@/assets/img/chloe_wechat.jpeg'
import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import './consult_qrcode.less';
import imgWechat from '../../asset/img/wechat.png'



function PkgConsultQrcode({ refVisible, changeVisible }, ref) {

    const [visible, setVisible] = useState(false)

	useEffect(() => {
        setVisible(refVisible)
    },[refVisible])

    const onClose = () => {
        setVisible(false)
        changeVisible(false)
	}

	return (

        <Modal className="pkgQrcodeCon"
			width={400}
			visible={visible}
			onCancel={onClose}
            // prefixCls= 'main-ant-modal'
			// afterClose={afterClose}
            centered
			footer={null}>
            <div className="container">
                <div className="right">
                    <div className="methodTitle"></div>
                    <div className="con">
                        <img src={imgWechat} alt="wechat"></img>
                        <p>扫码免费咨询<br />订制属于你的签证方案</p>
                    </div>
                </div>
            </div>
		</Modal>
	);
}

export default (PkgConsultQrcode)