// import styles from './index.less'
import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import './index.less'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { Helmet } from 'react-helmet';

function Help () {
    const { Panel } = Collapse;  
    const [ count, SetCount] = useState(3)
    const hiddeTidoLogo = (iframeContent) => {
        console.log('点击了')
        setTimeout(()=> {
            var element = document.querySelector('iframe[title="Tidio Chat"]');
            var iframeContent = element.contentDocument || element.contentWindow.document;
            var elementChild = iframeContent.querySelector('a[aria-label="Powered by Tidio."]');
            console.log(elementChild)
            // const body = iframeContent.querySelector('body');
            if (elementChild) {
                elementChild.parentNode.style.display = 'none'
            }
        },500)
    }

    useEffect(() => {
        setTimeout(() => {
            if (count > 0) {
                var element = document.querySelector('iframe[title="Tidio Chat"]');
                // console.log(element)
                if (element === null || element === undefined) {
                    SetCount(count-1)
                } else {
                    console.log(element)
                    var iframeContent = element.contentDocument || element.contentWindow.document;
                    console.log(iframeContent)
                    // element.addEventListener('click',hiddeTidoLogo)
                    const body = iframeContent.querySelector('body');
                    body.addEventListener('click',hiddeTidoLogo)
                }
                
            }
        }, 2000);
    },[count])

    return (
        <React.Fragment>
        <Helmet>
            <title>常见问题 FAQ</title>
            <meta
            name="description"
            content="从材料准备到预约面试，我们全程为你保驾护航！"
            />
            <meta
            name="keywords"
            content="美签攻略，美签咨询，美签预约，美签免面签申请，美签申请，美签代办，美签加急"
            />
        </Helmet>   
        <Header/>
        <div className="faqContainer">
            <div className="titleCon">
                <h2 className="title">常见问题</h2>
                <p className="titleDes">从材料准备到预约面试，我们全程为你保驾护航！</p>
            </div>
            <div className="faqQuestions">
                <Collapse bordered={false} className="faq_collapse">
                    <Panel header={<div data-seed="faq-02">正常应该提前多久准备面签？</div>}>
                        <p className="faq_answer">一般我们建议6-8周预留准备面签流程。前1-2周准备材料，预留2-4周预约心仪位置。</p>
                    </Panel>
                    <Panel header={<div data-seed="faq-01">预约面签位置需要准备什么？</div>}>
                        <p className="faq_answer">选择你需要的预约服务，付款后截图联系客服，提供一个已付款的预约账户，以及心仪城市+签证+时间线。</p>
                    </Panel>
                    <Panel header={<div data-seed="faq-01">是否支持第三方付款？</div>}>
                        <p className="faq_answer">加拿大本地支持Paypal、银行汇款E-Transfer,美国地区支持Venmo.</p>
                    </Panel>
                    <Panel header={<div data-seed="faq-03">开始安排预约后，我应该做什么？</div>}>
                        <p className="faq_answer">请确保你的设备都登出签证的账号，非必要时候请勿登录进去，若有需要想登录先联系我们。有任何好消息我们会第一时间通知你，并且你会收到领事馆的邮件通知。</p>
                    </Panel>
                    <Panel header={<div data-seed="faq-04">开始安排预约后等待多久有结果？</div>}>
                        <p className="faq_answer">根据近3年大数据统计，一般一周能帮你预约到心仪位置，如果你一周后仍未收到通知，证明你的账号还未成功安排到心仪位置，工作人员正在紧密锣鼓帮你安排预约。</p>
                        {/* <img className="faq_answer_qr_code" src='https://cdn.weetok.net/static/qrcode/qrcode_ben.jpg' alt='tech_ben_qr_code'></img> */}
                    </Panel>
                    <Panel
                        header={<div data-seed="pkg-resume-faq03">如果约不到心仪的位置能退费吗？</div>}>
                        <p className="faq_answer">不支持，如果没有你理想的位置放出，我们可以根据你的计划和需求帮你安排新的签证位置，如果你需要中断服务,需要你自己承担费用喔。</p>
                    </Panel>
                    <Panel header={<div data-seed="faq-01">材料准备需要准备什么？</div>}>
                        <p className="faq_answer">我们会提供清单列表来解放你的双手。</p>
                    </Panel>
                    <Panel header={<div data-seed="faq-05">成功预约后，因计划突然有变，可以重新安排新的预约吗？</div>}>
                       <p className="faq_answer">Canada Express团队可以免费重新帮你安排一次新的预约, 根据你的改动定制新的计划</p>
                    </Panel>
                    <Panel header={<div data-seed="faq-06">签证费付款后，我需要多久内预约？</div>}>
                        <p className="faq_answer">根据官方规定，有1年的有效期去安排预约位置。</p> 
                    </Panel>
                    <Panel header={<div data-seed="faq-07">如果同一天有多个面签时间段，会帮我们选择哪个？</div>}>
                        <p className="faq_answer">根据客户历史数据统计，我们会首选时间较早的，能提升面签通关率。</p> 
                    </Panel>
                    <Panel header={<div data-seed="faq-08">预约选位置时，系统提示 “There are no available appointments at the selected location. Please try again later.”</div>}>
                       <p className="faq_answer">你访问预约页面频率太高，系统自动把账号冻结6小时。请勿盲目刷位置，有问题请联系我们专业团队，为你安排预约。</p>
                    </Panel>
                    <Panel header={<div data-seed="faq-09">为什么我一直登陆不进预约系统？</div>}>
                        <p className="faq_answer">由于部分浏览器存在的兼容性问题，可能无法成功加载领事馆数据。请先尝试使用其他浏览器打开课程（推荐使用Google Chrome浏览器）。<br/>
                        如问题仍未解决，请用手机4G网（不连接WIFI）打开并检查是否能成功显示。</p> 
                    </Panel>
                    <Panel header={<div data-seed="faq-10">DS160确认单和预约到的使馆不是同一个城市可以吗？</div>}>
                       <p className="faq_answer">这种情况是没有问题的，只要你的DS160是你本人信息并且准确无误。若想更换，我可以提供协助。</p>
                    </Panel>
                    <Panel header={<div data-seed="faq-11">如果临时无法参加已经预约好面试怎么办？</div>}>
                       <p className="faq_answer">请提前至少48小时通知我们去取消面试。</p>
                    </Panel>
                    <Panel header={<div data-seed="faq-12">预约成功后未收到验证邮件？</div>}>
                        <p className="faq_answer">请先检查下验证邮件是否在邮箱的垃圾邮件（Spam）中，如果仍未找到，请发送邮件至<a href="mailto:support@caex-consulting.com">support@caex-consulting.com</a>，我们会尽快为你解决。</p>
                    </Panel>
                    <Panel header={<div data-seed="faq-11">预约过程中能否添加新的applicant？</div>}>
                       <p className="faq_answer">可以的，中途可以新增一个独立的applicant或者添加在正在预约过程中的一个group里。</p>
                    </Panel>
                </Collapse>
            </div>
            <div className="otherQuestion">
                <p>如有更多问题，请加微信<b>CAEX_SUPPORT</b>或发送邮件至<a href="mailto:support@caex-consulting.com">support@caex-consulting.com</a>，我们会尽快和你取得联系。</p>
            </div>
        </div>
        <Footer/>
        </React.Fragment>
    )
}

// function mapStateToProps(state) {
//     // window.wlog(state)
//     return{
//     }
    
// }

// export default connect(mapStateToProps)(Help)
export default Help