import React, { useContext, useState, createContext } from 'react';


// Create a context
const LocaleContext = createContext();

// Create a provider component
export const LocaleProvider = ({ children }) => {
  // Define your context data here
  const [locale, setLocale] = useState('cn');

  const changeLocale = (new_locale) => {
    // if (new_locale === 'cn') {
    //   console.log('改变了Locale')
    //   setLocale('en')
    // } else {
    //   setLocale('cn')
    // }
    setLocale(new_locale)
  };
  return (
    <LocaleContext.Provider value={{locale, changeLocale}}>
      {children}
    </LocaleContext.Provider>
  );
};

// Create a custom hook for using the context
export const useLocaleContext= () => {
  return useContext(LocaleContext);
};