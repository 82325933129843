import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './index.less'
import logo from '../../asset/img/ca-exlogo1.png'
import { useLocaleContext } from '../../utils/context'
import { Text } from '../../utils/locale'
import { PhoneFilled, EnvironmentFilled, MailFilled, ClockCircleFilled } from '@ant-design/icons';
import PlanConsultQrcode from '../../components/plan/consult_qrcode'

export default function Index({site='USA'}) {

    const [mobile,SetMobile] = useState(false)
    const { locale } = useLocaleContext()
    const [refVisibleConsult, setRefVisibleConsult] = useState(false)
    
    useEffect(() => {
        isMobile()
    },[])

    const open = (link) => {
        window.open(link)
    }

    const isMobile = () => {
        SetMobile(/(iPhone|iPod|Android|SymbianOS|Windows Phone)/i.test(navigator.userAgent))
    }

    const openConsult = e => {
        setRefVisibleConsult(true)
    }

    const changeVisibleConsult = e => {
        setRefVisibleConsult(e)
    }

    return (
        <>
            <PlanConsultQrcode refVisible={refVisibleConsult} changeVisible={changeVisibleConsult}/>
            <div className={site === 'USA' ? "footer-container" : "footer-container-ca"}>
                { mobile === false && <div className="footer">
                    {site === 'USA' &&
                        <>
                            <div className='logo'>
                                <img src={logo} alt=""></img>
                                <span>Canada Express</span>
                                <div className='privacy'>
                                    <p onClick={() => window.location.replace("/agreement")}>{Text.index89[locale]}</p>
                                    <p onClick={() => window.location.replace("/policy")}>{Text.index90[locale]}</p>
                                </div>
                            </div>
                            <div className="contact">
                                <div className='title'>{Text.index91[locale]}</div>
                                <div className='content'>
                                    <div>{Text.index92[locale]} </div>
                                    <div>微信客服：CAEX_SUPPORT</div>
                                    <div>{Text.index93[locale]}</div>
                                </div>
                            </div>
                            <div className="announce">
                                <div className='title'>{Text.index94[locale]}</div>
                                <div className='content'>
                                    <div className="content_link" onClick={() => open("https://ais.usvisa-info.com/en-ca/niv/information/announcements#810-update_validity_of_the_nonimmigrant_visa_fee_mrv_fee_20231011161357")}>Update: Validity of the Nonimmigrant Visa Fee (MRV Fee)</div>
                                    <div className="content_link" onClick={() => open("https://ais.usvisa-info.com/en-ca/niv/information/announcements#788-update_on_change_to_u_s_travel_policy_requiring_covid_19_vaccination_for_nonimmigrant_travel_20230512005010")}>COVID-19 Vaccination and Travel to the United States</div>
                                    <div className="content_link" onClick={() => open("https://ais.usvisa-info.com/en-ca/niv/information/announcements#803-validity_of_the_nonimmigrant_visa_fee_mrv_fee_20230818194707")}>Validity of the Nonimmigrant Visa Fee (MRV Fee)</div>
                                    {/* <div className="content_link" onClick={() => open("https://ais.usvisa-info.com/en-ca/niv/information/announcements#784-important_information_about_your_upcoming_visa_appointment_20230502144041")}>Important information about your upcoming visa appointment</div> */}
                                    {/* <div className="content_link" onClick={() => open("https://ais.usvisa-info.com/en-ca/niv/information/announcements#775-nonimmigrant_visa_processing_fee_increase_20230331193712")}>Nonimmigrant Visa Processing Fee Increase</div>
                                    <div className="content_link" onClick={() => open("https://ais.usvisa-info.com/en-ca/niv/information/announcements#770-long_lines_at_certain_post_offices_in_toronto_vancouver_20230309151541")}>Long lines at certain Post Offices in Toronto & Vancouver</div> */}
                                </div>
                            </div>
                        </>
                    }
                    {site === 'CANADA' &&
                        <>
                            <div className='logo'>
                                <img src={logo} alt=""></img>
                                <span>Canada Express</span>
                                <div className='privacy'>
                                    <p>Copyright © 2023 CAEX Canada Express. All rights reserved. </p>
                                </div>
                            </div>
                            <div className="contact">
                                <div className='title'>Support</div>
                                <div className='content'>
                                    <p onClick={e => openConsult(e)}>Wechat</p>
                                    <p><a href="mailto:support@caex-consulting.com, admin@caex-consulting.com">Support</a></p>
                                    <p onClick={() => window.open("/policy")}>Privacy</p>
                                    <p onClick={() => window.open("/agreement")}>Terms & Conditions</p>
                                </div>
                            </div>
                            <div className="announce">
                                <div className='title'>Contact Info</div>
                                <div className='content'>
                                    <div className="content_link" ><EnvironmentFilled /> 6060 Silver Dr, Burnaby, Vancouver</div>
                                    <div className="content_link" ><PhoneFilled /> (+1) - 7788 722881</div>
                                    <div className="content_link" ><MailFilled /> support@caex-consulting.com</div>
                                    <div className="content_link" ><ClockCircleFilled /> Mon - Fri : 9:00 am - 9:00 pm (PST)</div>
                                </div>
                            </div>
                        </>
                    }
                </div> }
                { mobile === true && site === 'USA' && <div className="footer-fix"> 
                    {/* <div className="copyright">Copyright © 2024, CAEX Canada Express Co.</div> */}
                </div>}
                { site === 'USA' && <div className="copyright">Copyright © 2024, CAEX Canada Express Co.</div> }

                { mobile === true && site === 'CANADA' && 
                <>
                    <div className="footer-fix"> </div>
                    <div className="copyright">Copyright © 2024, CAEX Canada Express Co.</div> 
                </>
                }
            </div>
        </>
    )
}