import React, { useEffect, useState } from 'react';
import { Result, Typography } from 'antd';
import './style.less';
import Header from '../../components/header'
import wechat from '../../asset/img/wechat.png'

const { Paragraph, Text } = Typography;

export default function Cancel() {
  const [cancelRedirect, SetcancelRedirect] = useState("/plan")

  useEffect(() => {
    const query = window.location.search
    if (query === "") {
      window.location.replace(window.location.origin+"/payresult/cancel?type=1")    
    }
    const type = query.split("?type=")[1]
    if (type === "1") {
      SetcancelRedirect("/plan")
    } else if (type === "2") {
      SetcancelRedirect("/subscribe")
    } else {
      SetcancelRedirect("/docs")
    }
   
  }, [])
  return (
    <>
      <Header blank={true}/>
      <div className="payresult_wrap">
        <Result
        className="result_wrap"
        status="warning"
			  title="支付已取消 / 失败"
      >
        <div className="desc">
          <Paragraph>
            <Text strong style={{fontSize: 14,}}>接下来你可以：</Text>
          </Paragraph>
          <Paragraph>
            <ul>
              <li><Text strong style={{fontSize: 14,}}><a href={cancelRedirect}>点击这里</a> 查看服务类型。重新前往支付</Text></li>
              <li>或者请将你的姓名和付款失败的截屏发送给客服</li>
              <img src={wechat} className="img_qrcode" style={{width:'150px',margin:'20px auto',display:'block'}} alt="" />
            </ul>
          </Paragraph>
        </div>
      </Result> 
      </div>
    </>
  )
}